import { get } from 'lodash';
import React from 'react';

import { ApplicationDescriptor, ContextParams } from './helpers/processDiscoveryHelper';
import { fetchProcessDiscoveryData } from './customHooks/useProcessDiscoveryResults';
import eventBus from './helpers/EventBus';

interface CustomLinkRendererProps {
  formattedValue: string;
  primaryColumnValue: string;
  processDiscoveryContext: ContextParams;
  selectedActionForExistingColumn: string;
}

const CustomLinkRenderer = ({
  formattedValue,
  processDiscoveryContext,
  primaryColumnValue,
  selectedActionForExistingColumn
}: CustomLinkRendererProps) => {
  const onClick = async () => {
    try {
      eventBus.dispatch('VISUALIZATION_LOADING', true);
      const response = await fetchProcessDiscoveryData({
        primaryColumnValue,
        contextParams: processDiscoveryContext
      });
      const applicationDescriptors = get(response, '0.applicationDescriptors', []);
      const url = applicationDescriptors.find(
        (descriptor: ApplicationDescriptor) => descriptor.id === selectedActionForExistingColumn
      )?.url;
      window.open(url, '_blank');
      eventBus.dispatch('VISUALIZATION_LOADING', false);
    } catch (error) {
      eventBus.dispatch('VISUALIZATION_LOADING', false);
    }
  };

  return (
    <div className="link-value" onClick={onClick}>
      {formattedValue}
    </div>
  );
};

export default CustomLinkRenderer;
