// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

// Project Imports
import { formatValuePlainText } from '../helpers/ColumnFormattingHelpers';
import { getMeasures } from '../helpers/measure';
import { calculateTextSize, getTranslation, hideOffscreenDimensionLabels } from '../helpers/SvgHelpers';
import BaseVisualization from './BaseVisualization';
import { renderLegend } from './BaseVisualization/Legend';
import SvgKeyboardPanning from './SvgKeyboardPanning';
import LabelResizer from './LabelResizer';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import {
  getAxisLabels,
  getReferenceLinesWithValues,
  getDimensionAxisMaxValue,
  getDimensionAxisMinValue,
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getMeasureAxisScale,
  getShowDimensionLabels,
  getShowValueLabels
} from 'common/visualizations/helpers/VifSelectors';

// Constants
import {
  ANNOTATIONS,
  AXIS_DEFAULT_COLOR,
  AXIS_GRID_COLOR,
  AXIS_LABEL_MARGIN,
  AXIS_TICK_COLOR,
  D3_TICK_SIZE,
  DATA_POINT_LABELS_FONT_COLOR,
  DATA_POINT_LABELS_FONT_SIZE,
  DATA_POINT_LABELS_OFFSET_ABOVE,
  DATA_POINT_LABELS_OFFSET_ABOVE_MORE,
  DATA_POINT_LABELS_OFFSET_BELOW,
  DATA_POINT_LABELS_OFFSET_BELOW_MORE,
  DEFAULT_CIRCLE_HIGHLIGHT_RADIUS,
  DEFAULT_DESKTOP_COLUMN_WIDTH,
  DEFAULT_LINE_HIGHLIGHT_FILL,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  DIMENSION_LABELS_DEFAULT_HEIGHT,
  DIMENSION_LABELS_FONT_COLOR,
  DIMENSION_LABELS_FONT_SIZE,
  FONT_STACK,
  LINE_DASH_ARRAY,
  MEASURE_AXIS_SCALE_MIN_TO_MAX,
  MEASURE_LABELS_FONT_COLOR,
  MEASURE_LABELS_FONT_SIZE,
  REFERENCE_LINES_STROKE_DASHARRAY,
  REFERENCE_LINES_STROKE_WIDTH,
  REFERENCE_LINES_UNDERLAY_THICKNESS,
  SERIES_TYPE_TIMELINE_CHART
} from './SvgConstants';

// The MARGINS values have been eyeballed to provide enough space for axis
// labels that have been observed 'in the wild'. They may need to be adjusted
// slightly in the future, but the adjustments will likely be small in scale.
// The LEFT margin has been removed because it will be dynamically calculated.
const MARGINS = {
  TOP: 32,
  RIGHT: 50,
  BOTTOM: 32
};
const AREA_DOT_RADIUS = 1;
const AREA_STROKE_WIDTH = 3;
const DIMENSION_LABELS_TIME_FIXED_HEIGHT = 24;
const LINE_DOT_RADIUS = 2;
const LINE_STROKE_WIDTH = 3;
const MAX_ROW_COUNT_WITHOUT_PAN = 1000;
const MINIMUM_HIGHLIGHT_WIDTH = 5;
const RECOMMENDED_TICK_DISTANCE = 150;

function SvgTimelineChart($element, vif, options) {
  const self = this;
  const parseDate = d3.time
    // A 'floating timestamp', e.g. '2008-01-18T00:00:00.000' (Note the lack of
    // timezone information, since we need to treat all dates as in the
    // browser's local timezone when working with them in the browser, but treat
    // them as the same datetime, just in UTC, when communicating with the
    // outside world. We do this by selectively adding/removing the 'Z' for UTC
    // to datetimes represented as ISO-8601 strings).
    .format('%Y-%m-%dT%H:%M:%S.%L').parse;

  let $chartElement;
  let bisectorDates;
  let d3XScale;
  let d3YScale;
  let dataToRenderBySeries;
  let firstNonFlyoutSeries;
  let flyoutDataToRender;
  let isUsingTimeScale;
  let lastRenderedSeriesWidth = 0;
  let lastRenderedZoomTranslate = 0;
  let maxYValue;
  let measures;
  let minYValue;
  let precision;
  let referenceLines;
  let timelineDataToRender;
  let annotationsToRender;
  let xAxisPanDistanceFromZoom = 0;

  const labelResizer = new LabelResizer({
    enabled: false,
    getAxisLabels: () => getAxisLabels(self.getVif()),
    margins: MARGINS,
    onDrag: () => {
      renderData();
      hideFlyout();
    },
    onDragEnd: (state) => {
      renderData();
      self.emitEvent('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', state.overriddenAreaSize);
    }
  });

  _.extend(this, new BaseVisualization($element, vif, options));

  renderTemplate();

  /**
   * Public methods
   */

  this.render = ({ newColumns, newComputedColumns, newData, newFlyoutData, newVif, newTableVif } = {}) => {
    if (!newData && !newFlyoutData && !timelineDataToRender) {
      return;
    }

    labelResizer.resetOverride();

    this.clearError();

    if (newVif) {
      this.updateVif(newVif);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (newData) {
      timelineDataToRender = newData;
      self.setDefaultMeasureColumnPrecision(timelineDataToRender);

      isUsingTimeScale = isDimensionCalendarDate(
        _.get(self.getVif(), 'series[0].dataSource.dimension.columnName'),
        timelineDataToRender.columnFormats
      );
      const parseAnnotationDate = d3.time.format('%Y-%m-%d').parse;

      const annotations = _.chain(self.getVif())
        .get('series[0].annotations', [])
        .map((annotation, index) => {
          if (!_.isNil(annotation.date)) {
            return [parseAnnotationDate(annotation.date), annotation.description, index + 1];
          }
          return null;
        })
        .without(null)
        .value();
      annotationsToRender = isUsingTimeScale ? annotations : [];

      const defaultHeight = isUsingTimeScale ? 0 : DIMENSION_LABELS_DEFAULT_HEIGHT;
      labelResizer.options.getConfiguredLabelHeight = () =>
        _.get(self.getVif(), 'configuration.dimensionLabelAreaSize', defaultHeight);

      lastRenderedZoomTranslate = 0;
      measures = getMeasures(self, timelineDataToRender);

      // Note: the vast majority of rendering code reads from
      // 'dataToRenderBySeries', not 'dataToRender'. If memory usage becomes
      // problematic, the few places that read from 'dataToRender' can be
      // modified to read from 'dataToRenderBySeries' instead, trading a bit
      // of extra computation for halving memory usage. In this case, we would
      // not want to store a reference to 'newData' at all but rather just
      // to the result of 'mapDataTableToDataTablesBySeries()'.
      dataToRenderBySeries = mapDataTableToDataTablesBySeries(timelineDataToRender);
    }

    if (newFlyoutData) {
      flyoutDataToRender = newFlyoutData;
      self.setDefaultMeasureColumnPrecision(flyoutDataToRender);
    }

    if (self.isOnlyNullValues(timelineDataToRender) || timelineDataToRender.rows.length < 1) {
      self.renderNoDataError();
      return;
    }

    referenceLines = getReferenceLinesWithValues(self.getVif());

    labelResizer.updateOptions({ enabled: getShowDimensionLabels(self.getVif()) });
    renderData();
  };

  this.invalidateSize = () => {
    if ($chartElement && timelineDataToRender) {
      renderData();
    }
  };

  this.destroy = () => {
    const rootElement = d3.select(this.$element[0]);

    rootElement.select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */
  function renderTemplate() {
    $chartElement = $('<div>', { class: 'timeline-chart chart-with-label-dragger' });

    labelResizer.renderTemplate($chartElement);

    self.$element.find('.socrata-visualization-chart-container').append($chartElement);
  }

  // We are moving in the direction of representing multi-series data as a
  // single data table with one column for the dimension and then n columns for
  // measures, where n is the number of series. We believe this to be sensible
  // in the general case, but it conflicts with the way d3 expects to be given
  // multi-series data for lines/areas, and so we (somewhat wastefully) build
  // up a single data table object from n query results in the data fetching
  // code and then almost immediately turn around and deconstruct it back into
  // multiple independent tables, one for each series, when rendering a
  // Timeline Chart.
  //
  // It was felt that having a single data format across the entire library and
  // then messing with it to make it easier to draw Timeline charts (at a
  // slightly elevated processing/memory cost) was preferable to the
  // alternative (in other words, we have optimized for clarity and
  // predictability as opposed to rendering performance).
  function mapDataTableToDataTablesBySeries(dataTable) {
    const dataTableDimensionIndex = dataTable.columns.indexOf('dimension');

    const filteredMeasures = _.filter(measures, (measure) => {
      return _.get(measure, 'palette.series.type', '') === SERIES_TYPE_TIMELINE_CHART;
    });

    const dataTablesBySeries = filteredMeasures.map((measure, i) => {
      const dataTableMeasureIndex = dataTableDimensionIndex + 1 + i;
      let rows = dataTable.rows.map((row) => {
        return [row[dataTableDimensionIndex], row[dataTableMeasureIndex]];
      });

      // Results with no precision are not bucketed and may have null dimension
      // values or null measure values. If so, filter them out.
      const precision = _.get(self.getVif(), 'series[0].dataSource.precision');

      if (precision === 'none') {
        rows = rows.filter(
          (row) => !_.isNull(row[dataTableDimensionIndex]) && !_.isNull(row[dataTableMeasureIndex])
        );
      }

      return {
        columns: ['dimension', measure.tagValue],
        measure,
        rows
      };
    });

    return dataTablesBySeries;
  }

  /**
   * Visualization renderer and helper functions
   */
  function renderData() {
    const dataTableDimensionIndex = timelineDataToRender.columns.indexOf('dimension');

    referenceLines = getReferenceLinesWithValues(self.getVif());

    const minimumDatumWidth = self.isMobile() ? DEFAULT_MOBILE_COLUMN_WIDTH : DEFAULT_DESKTOP_COLUMN_WIDTH;

    const dimensionLabelsHeight = getShowDimensionLabels(self.getVif())
      ? labelResizer.computeLabelHeight()
      : 0;

    const axisLabels = getAxisLabels(self.getVif());
    const rightMargin = MARGINS.RIGHT + (axisLabels.right ? AXIS_LABEL_MARGIN : 0);
    const topMargin = MARGINS.TOP + (axisLabels.top ? AXIS_LABEL_MARGIN : 0);
    const bottomMargin = MARGINS.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0) + dimensionLabelsHeight;
    let viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

    firstNonFlyoutSeries = self.getFirstSeriesOfType(['timelineChart']);

    const leftMargin =
      self.calculateLeftOrRightMargin({
        dataToRender: timelineDataToRender,
        height: viewportHeight,
        isSecondaryAxis: false,
        series: firstNonFlyoutSeries
      }) + (axisLabels.left ? AXIS_LABEL_MARGIN : 0);

    let viewportWidth = Math.max(0, $chartElement.width() - leftMargin - rightMargin);

    // There may be instances where the chart is rendering in a hidden element. If this
    // happens, we shouldn't need to continue through this function. Allowing processing
    // to continue and calling `renderLegend()` would put the chart in an state where
    // parts of the chart don't render properly once they come into view (see EN-40617).
    if (viewportHeight <= 0 || viewportWidth <= 0) {
      return;
    }

    const panningClipPathId = `timeline-chart-panning-clip-path-${_.uniqueId()}`;
    const measureBoundsClipPathId = `timeline-chart-measure-bounds-clip-path-${_.uniqueId()}`;
    const dimensionValues = timelineDataToRender.rows.map((row) => row[dataTableDimensionIndex]);
    const seriesDimensionIndex = 0;
    const seriesMeasureIndex = 1;

    let annotationDotsSvg;
    let annotationTextSvg;
    let annotationsSvg;
    let annotationsXAxisValues = [];
    let chartSvg;
    let d3AreaCategoricalSeries;
    let d3AreaTimeSeries;
    let d3LineCategoricalSeries;
    let d3LineTimeSeries;
    let d3XAxis;
    let d3YAxis;
    let d3Zoom;
    let domainEndDate;
    let domainStartDate;
    let endDate;
    let height;
    let referenceLineSvgs;
    let referenceLineUnderlaySvgs;
    let rootElement;
    let startDate;
    let viewportSvg;
    let width;
    let xAxisAndSeriesSvg;
    let xAxisBound = false;
    let xAxisPanDistance;
    let xAxisPanningEnabled;

    precision = _.get(self.getVif(), 'series[0].dataSource.precision');

    // See comment in renderXAxis() for an explanation as to why this is
    // separate.
    function bindXAxisOnce() {
      if (!xAxisBound) {
        const renderedXAxisSvg = viewportSvg.select('.x.axis');
        const renderedXAxisBaselineSvg = viewportSvg.select('.x.axis.baseline');
        let xAxisFormatter;

        if (getShowDimensionLabels(self.getVif())) {
          xAxisFormatter = d3XAxis;
        } else {
          xAxisFormatter = d3XAxis.tickFormat('').tickSize(0);
        }

        renderedXAxisSvg.call(xAxisFormatter);

        renderedXAxisBaselineSvg.call(d3XAxis.tickFormat('').tickSize(0));

        xAxisBound = true;
      }
    }

    function renderXAxis() {
      if (isUsingTimeScale) {
        renderTimeXAxis();
      } else {
        renderCategoricalXAxis();
      }
    }

    function renderCategoricalXAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderXAxis() as idempotent.
      bindXAxisOnce();

      const xAxisSvg = viewportSvg.select('.x.axis');
      const xBaselineSvg = viewportSvg.select('.x.axis.baseline');

      xAxisSvg.attr('transform', `translate(0,${height})`);

      xAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_DEFAULT_COLOR);

      xAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_TICK_COLOR);

      xAxisSvg
        .selectAll('text')
        .attr('class', 'text-dimension')
        .attr('fill', DIMENSION_LABELS_FONT_COLOR)
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${DIMENSION_LABELS_FONT_SIZE}px`)
        .attr('stroke', 'none')
        .attr('data-row-index', (label, rowIndex) => rowIndex)
        .call(self.rotateDimensionLabels, {
          dataToRender: timelineDataToRender,
          maxHeight: dimensionLabelsHeight,
          maxWidth: d3XScale.rangeBand()
        });

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });

      let baselineValue;

      if (minYValue > 0) {
        baselineValue = minYValue;
      } else if (maxYValue < 0) {
        baselineValue = maxYValue;
      } else {
        baselineValue = 0;
      }

      xBaselineSvg.attr('transform', `translate(0,${d3YScale(baselineValue)})`);

      xBaselineSvg
        .selectAll('line, path')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_DEFAULT_COLOR);
    }

    function renderTimeXAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderXAxis() as idempotent.
      bindXAxisOnce();

      const renderedXAxisSvg = viewportSvg.select('.x.axis');
      const renderedXAxisBaselineSvg = viewportSvg.select('.x.axis.baseline');

      renderedXAxisSvg.attr('transform', `translate(0,${height})`);

      renderedXAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_DEFAULT_COLOR);

      renderedXAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      renderedXAxisSvg
        .selectAll('text')
        .attr('fill', DIMENSION_LABELS_FONT_COLOR)
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${DIMENSION_LABELS_FONT_SIZE}px`)
        .attr('stroke', 'none');

      const baselineValue = _.clamp(0, minYValue, maxYValue);

      renderedXAxisBaselineSvg.attr('transform', `translate(0,${d3YScale(baselineValue)})`);

      renderedXAxisBaselineSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    function renderYAxis() {
      const renderedYAxisSvg = viewportSvg.select('.y.axis');
      const renderedYAxisGridSvg = viewportSvg.select('.y.grid');

      renderedYAxisSvg.call(d3YAxis);

      renderedYAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      renderedYAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      renderedYAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
        .attr('fill', MEASURE_LABELS_FONT_COLOR)
        .attr('stroke', 'none');

      let d3YGridAxis = d3YAxis.tickSize(viewportWidth).tickFormat('');

      renderedYAxisGridSvg.attr('transform', `translate(${viewportWidth},0)`).call(d3YGridAxis);

      renderedYAxisGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');

      renderedYAxisGridSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_GRID_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    function renderReferenceLines() {
      const getYPosition = (referenceLine) => d3YScale(referenceLine.value);
      const getLineThickness = (referenceLine) => {
        return self.isInRange(referenceLine.value, minYValue, maxYValue) ? REFERENCE_LINES_STROKE_WIDTH : 0;
      };

      const getUnderlayThickness = (referenceLine) => {
        return self.isInRange(referenceLine.value, minYValue, maxYValue)
          ? REFERENCE_LINES_UNDERLAY_THICKNESS
          : 0;
      };

      // This places the underlay half above the line and half below the line.
      const underlayUpwardShift = REFERENCE_LINES_UNDERLAY_THICKNESS / 2;

      referenceLineUnderlaySvgs
        .attr('data-reference-line-index', (referenceLine, index) => index)
        .attr('fill', DEFAULT_LINE_HIGHLIGHT_FILL)
        .attr('fill-opacity', 0)
        .attr('x', 0)
        .attr('y', (referenceLine) => getYPosition(referenceLine) - underlayUpwardShift)
        .attr('width', width)
        .attr('height', getUnderlayThickness);

      referenceLineSvgs
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', (referenceLine) => referenceLine.color)
        .attr('stroke-dasharray', REFERENCE_LINES_STROKE_DASHARRAY)
        .attr('stroke-width', getLineThickness)
        .attr('x1', 0)
        .attr('y1', getYPosition)
        .attr('x2', width)
        .attr('y2', getYPosition);
    }

    function renderValues() {
      dataToRenderBySeries.forEach((seriesData, seriesIndex) => {
        renderLines(seriesData, seriesIndex);
        renderCircles(seriesData, seriesIndex);
      });
    }

    function renderLines(seriesData, seriesIndex) {
      const { measure } = seriesData;
      const seriesTypeVariant = self.getTypeVariantBySeriesIndex(seriesIndex);
      const { pattern } = self.getLineStyleBySeriesIndex(seriesIndex);
      const dasharray = pattern === 'dashed' ? LINE_DASH_ARRAY : null;

      // If we *are not* drawing a line chart, we need to draw the area fill
      // first so that the line sits on top of it in the z-stack.
      if (seriesTypeVariant !== 'line') {
        let seriesAreaSvg = viewportSvg.select(`.series-${seriesIndex}-${seriesTypeVariant}-area`);

        const d3AreaSeries = isUsingTimeScale
          ? d3AreaTimeSeries[seriesIndex]
          : d3AreaCategoricalSeries[seriesIndex];

        seriesAreaSvg
          .attr('d', d3AreaSeries)
          .attr('clip-path', `url(#${panningClipPathId})`)
          // Note that temporarily, the type variant defaults to 'area' if it
          // is not set, but direction from UX is to never show a shaded area,
          // only the area's top contour. As such, we make the area itself
          // transparent (instead of getting a color from the VIF or via the
          // measure palette.
          .attr('fill', 'transparent')
          .attr('stroke', 'transparent')
          .attr('stroke-width', AREA_STROKE_WIDTH)
          .attr('opacity', '0.1');
      }

      const color = measure.getColor();

      // We draw the line for all type variants of timeline chart.
      let seriesLineSvg = viewportSvg.select(`.series-${seriesIndex}-${seriesTypeVariant}-line`);

      const d3LineSeries = isUsingTimeScale
        ? d3LineTimeSeries[seriesIndex]
        : d3LineCategoricalSeries[seriesIndex];

      seriesLineSvg
        .attr('d', d3LineSeries)
        .attr('clip-path', `url(#${panningClipPathId})`)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', LINE_STROKE_WIDTH)
        .attr('stroke-dasharray', dasharray);
    }

    function renderCircles(seriesData, seriesIndex) {
      const seriesTypeVariant = self.getTypeVariantBySeriesIndex(seriesIndex);
      const lineStyle = self.getLineStyleBySeriesIndex(seriesIndex);

      const seriesDotsPathSvg = viewportSvg.select(`.series-${seriesIndex}-line-dots`);
      seriesDotsPathSvg.attr('clip-path', `url(#${panningClipPathId})`);

      const seriesDotsSvg = seriesDotsPathSvg.selectAll('circle');
      let fill = 'transparent';
      let defaultRadius;

      // As opposed to only on hover, or even never.
      const alwaysShowPoints = lineStyle.points !== 'none';
      if (alwaysShowPoints) {
        fill = seriesData.measure.getColor();
      }

      if (isUsingTimeScale) {
        // If we *are* drawing a line chart we also draw the dots bigger to
        // indicate individual points in the data. If we are drawing an area
        // chart the dots help to indicate non-contiguous sections which may
        // be drawn at 1 pixel wide and nearly invisible with the fill color
        // alone.
        defaultRadius = seriesTypeVariant === 'line' ? LINE_DOT_RADIUS : AREA_DOT_RADIUS;
      } else {
        // Categorical scale uses the same size dots as the combo chart
        defaultRadius = alwaysShowPoints ? LINE_DOT_RADIUS : DEFAULT_CIRCLE_HIGHLIGHT_RADIUS;
      }

      const radius = _.isFinite(lineStyle.pointRadius) ? lineStyle.pointRadius : defaultRadius;

      const getCx = (d) => {
        if (!isUsingTimeScale) {
          const halfBandWidth = Math.round(d3XScale.rangeBand() / 2.0);
          return d3XScale(d[seriesDimensionIndex]) + halfBandWidth;
        } else if (allSeriesAreLineVariant()) {
          return d3XScale(parseDate(d[seriesDimensionIndex]));
        } else if (precision === 'none') {
          return d3XScale(parseDate(d[seriesDimensionIndex]));
        } else if (lineStyle.horizontalAlignment !== 'middle') {
          return d3XScale(parseDate(d[seriesDimensionIndex]));
        } else {
          // For area (bucketed) variants, we need to shift the rendered
          // points by half the width of the domain interval to the right
          // in order for the peaks to appear in the middle of the
          // intervals, as opposed to the beginning of them (if we do not
          // do this shift, the a range of 2001-2002 and a value of 1000
          // looks like the 1000 was measured on Jan 1, 2001).
          return d3XScale(
            new Date(
              parseDate(d[seriesDimensionIndex]).getTime() + getSeriesHalfIntervalWidthInMs(seriesData)
            )
          );
        }
      };

      const getCy = (d) => {
        const rowValue = d[seriesMeasureIndex];
        return rowValue !== null ? d3YScale(rowValue) : -100;
      };

      // Only one open circle supported today.
      const openCircleIndex =
        lineStyle.points === 'last-open'
          ? _.findLastIndex(seriesDotsSvg.data(), (row) => !_.isNull(row[seriesMeasureIndex]))
          : null;

      const openCircleLineWidth = radius / 2;

      seriesDotsSvg
        .attr('cx', getCx)
        .attr('cy', getCy)
        .attr('data-dimension-index', (d, index) => index)
        .attr('data-series-index', seriesIndex)
        // Circles have two forms. If the circle is closed, it's a simple
        // radius and fill. If it's open, it's a radius, stroke, and fill.
        .attr('data-default-fill', (d, i) => (i === openCircleIndex ? 'white' : fill))
        .attr('fill', (d, i) => (i === openCircleIndex ? 'white' : fill))
        .attr('stroke-width', (d, i) => (i === openCircleIndex ? openCircleLineWidth : 0))
        .attr('stroke', fill) // No effect if stroke-width is 0.
        .attr('r', (d, i) =>
          // Divide openCircleLineWidth by 2 because half the stroke lies outside
          // the radius, half lies inside.
          i === openCircleIndex ? radius - openCircleLineWidth / 2 : radius
        );

      const getValue = (d) =>
        self.getMeasureColumnFormattedValueText({
          dataToRender: timelineDataToRender,
          measureIndex: seriesIndex,
          value: d[1]
        });

      const getTextTranslation = (d, index) => {
        const y = getCy(d); // y position is the baseline (bottom) of the text element
        let yOffset;

        if (index % 2 == 0) {
          yOffset = DATA_POINT_LABELS_OFFSET_BELOW;

          if (y + yOffset > height) {
            yOffset = DATA_POINT_LABELS_OFFSET_ABOVE_MORE;
          }
        } else {
          yOffset = DATA_POINT_LABELS_OFFSET_ABOVE;

          if (y + yOffset - DATA_POINT_LABELS_FONT_SIZE < 0) {
            // include text element height
            yOffset = DATA_POINT_LABELS_OFFSET_BELOW_MORE;
          }
        }

        const translateY = y + yOffset;
        const translateX = getCx(d);
        return `translate(${translateX} ${translateY})`;
      };

      const getTextFill = (d) => {
        // Only fill the labels of the points that are on-screen.
        const showValueLabels = d[1] <= maxYValue && d[1] >= minYValue;
        return showValueLabels ? DATA_POINT_LABELS_FONT_COLOR : 'transparent';
      };

      if (getShowValueLabels(self.getVif())) {
        seriesDotsPathSvg
          .selectAll('text.text-series')
          .attr('fill', getTextFill)
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${DATA_POINT_LABELS_FONT_SIZE}px`)
          .attr('style', 'text-anchor: middle')
          .attr('transform', getTextTranslation)
          .text(getValue);
      }
    }

    function handleZoom() {
      lastRenderedZoomTranslate = _.clamp(d3.event.translate[0], -xAxisPanDistance, 0);
      xAxisPanDistanceFromZoom = lastRenderedZoomTranslate;

      // We need to override d3's internal translation since it doesn't seem to
      // respect our snapping to the beginning and end of the rendered data.
      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);

      translateAnnotations(lastRenderedZoomTranslate);

      if (self.isMobile()) {
        hideHighlight();
        hideFlyout();
      }

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });
    }

    function translateAnnotations(lastRenderedZoomTranslate) {
      annotationsSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);
    }

    function restoreLastRenderedZoom() {
      const translateXRatio =
        lastRenderedSeriesWidth !== 0 ? Math.abs(lastRenderedZoomTranslate / lastRenderedSeriesWidth) : 0;
      const currentWidth = xAxisAndSeriesSvg.node().getBBox().width;

      lastRenderedZoomTranslate = _.clamp(-translateXRatio * currentWidth, -xAxisPanDistance, 0);

      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);

      translateAnnotations(lastRenderedZoomTranslate);
    }

    function renderAnnotations() {
      const getCx = (d) => {
        return d3XScale(d[seriesDimensionIndex]) + ANNOTATIONS.CIRCLE_RADIUS / 2;
      };

      const getAnnotationCy = (d, i) => {
        const xScale = _.get(annotationsXAxisValues, i);
        const yRange = d3YScale.range();
        const yHeight = yRange[0] + yRange[1];
        const yCenter = yHeight / 2;
        const circleSpace = 2 * ANNOTATIONS.CIRCLE_RADIUS + ANNOTATIONS.CIRCLE_PADDING;
        const circleCount = Math.floor(yCenter / circleSpace);
        const xScaleCount = _.chain(annotationsXAxisValues)
          .take(i + 1)
          .filter((xAxisValue) => xAxisValue === xScale)
          .size()
          .value();

        if (circleCount > xScaleCount) {
          const paddingValue = xScaleCount * circleSpace;
          return yCenter - paddingValue;
        } else {
          const paddingValue = (xScaleCount - circleCount) * circleSpace;
          return yCenter + paddingValue;
        }
      };

      annotationDotsSvg
        .attr('cx', getCx)
        .attr('cy', getAnnotationCy)
        .attr('fill', ANNOTATIONS.CIRCLE_COLOR)
        .attr('stroke', ANNOTATIONS.CIRCLE_COLOR)
        .attr('r', ANNOTATIONS.CIRCLE_RADIUS);

      annotationTextSvg
        .attr('font-size', ANNOTATIONS.TEXT_FONT_SIZE)
        .attr('style', 'text-anchor: middle')
        .attr('pointer-events', 'none')
        .attr('alignment-baseline', 'central')
        .attr('fill', ANNOTATIONS.TEXT_FILL_COLOR)
        .attr('x', getCx)
        .attr('y', getAnnotationCy)
        .text((d) => _.get(d, ANNOTATIONS.TEXT_COUNT_INDEX));
    }
    const annotations = _.map(annotationsToRender, (annotation) => {
      const [date, description] = annotation;
      const formattedDate = formatDateForFlyout(date);
      return { date: formattedDate, description };
    });
    // Actual execution begins here.
    const adjustedViewportSize = renderLegend(self, {
      annotations,
      measures,
      referenceLines,
      viewportSize: {
        height: viewportHeight,
        width: viewportWidth
      }
    });

    viewportHeight = adjustedViewportSize.height;
    viewportWidth = adjustedViewportSize.width;

    if (self.getXAxisScalingModeBySeriesIndex(0) === 'fit') {
      width = viewportWidth;
      xAxisPanningEnabled = false;

      if (timelineDataToRender.rows.length > MAX_ROW_COUNT_WITHOUT_PAN) {
        const error = formatString(
          I18n.t('shared.visualizations.charts.timeline_chart.error_exceeded_max_row_count_without_pan'),
          MAX_ROW_COUNT_WITHOUT_PAN
        );
        self.renderError(error);
        return;
      }
    } else {
      width = Math.max(viewportWidth, minimumDatumWidth * timelineDataToRender.rows.length);

      xAxisPanDistance = width - viewportWidth;
      xAxisPanningEnabled = viewportWidth !== width;

      if (xAxisPanningEnabled) {
        self.showPanningNotice();
      } else {
        self.hidePanningNotice();
      }
    }

    // We only calculate the height after we have shown or hidden the panning
    // notice, since its presence or absence affects the total height of the
    // viewport.
    if (!isUsingTimeScale && getShowDimensionLabels(self.getVif())) {
      height = viewportHeight;
    } else {
      height = Math.max(0, viewportHeight - DIMENSION_LABELS_TIME_FIXED_HEIGHT);
    }

    // Next we can set up some data that we only want to compute once.
    //
    // The bisector is used to determine which data point or bucket to
    // highlight when moving along the x-axis.  For bucketed variants,
    // the axis is bisected on the dates themselves because the displayed
    // point is shifted to be in the middle of the bucket.
    //
    // For non-bucketed, the displayed point is the actual data point, so
    // we want to do the opposite, and set up bisector dates that are
    // mid-way between the data points in order for the highlight to
    // behave they way you would expect as you mouse along the x-axis.
    //
    if (isUsingTimeScale) {
      if (precision !== 'none') {
        bisectorDates = timelineDataToRender.rows.map((d) => parseDate(d[seriesDimensionIndex]));
      } else {
        bisectorDates = getPrecisionNoneBisectorDates(timelineDataToRender.rows);
      }

      const customStartDate = getDimensionAxisMinValue(self.getVif());
      const customEndDate = getDimensionAxisMaxValue(self.getVif());

      if (customStartDate) {
        startDate = customStartDate;
      } else {
        startDate = d3.min(
          // Second, get the min dimension date of all series
          dataToRenderBySeries.map((series) => {
            // First, get the min dimension date of rows in a series
            return d3.min(series.rows, (d) => d[seriesDimensionIndex]);
          })
        );
      }

      if (customEndDate) {
        endDate = customEndDate;
      } else {
        endDate = d3.max(
          // Second, get the max dimension date of all series
          dataToRenderBySeries.map((series) => {
            // First, get the max dimension date of rows in a series
            return d3.max(series.rows, (d) => d[seriesDimensionIndex]);
          })
        );
      }

      domainStartDate = parseDate(startDate);
      domainEndDate = parseDate(endDate);

      // Add 1 year, month, week or day (depending on the precision) so that we render
      // the last time bucket properly.
      //
      if (precision !== 'none') {
        domainEndDate = getIncrementedDateByPrecision(domainEndDate, timelineDataToRender.precision);
        endDate = domainEndDate.toISOString();
      }
    }

    try {
      const measureAxisMinValue = getMeasureAxisMinValue(self.getVif());
      const measureAxisMaxValue = getMeasureAxisMaxValue(self.getVif());

      if (measureAxisMinValue && measureAxisMaxValue && measureAxisMinValue >= measureAxisMaxValue) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_min_should_be_lesser_then_max'
          )
        );
        return;
      }

      const rowValues = _.flatMap(timelineDataToRender.rows, (row) => row.slice(dataTableDimensionIndex + 1));

      const extent = self.getRowValueExtent({
        referenceLines,
        rowValues
      });

      if (measureAxisMaxValue) {
        maxYValue = measureAxisMaxValue;
      } else if (getMeasureAxisScale(self.getVif()) === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
        maxYValue = extent.max;
      } else {
        maxYValue = Math.max(extent.max, 0);
      }

      if (measureAxisMinValue) {
        minYValue = measureAxisMinValue;
      } else if (getMeasureAxisScale(self.getVif()) === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
        minYValue = extent.min;
      } else {
        minYValue = Math.min(extent.min, 0);
      }
    } catch (error) {
      self.renderError(error.message);
      return;
    }

    d3XScale = isUsingTimeScale
      ? generateTimeXScale(domainStartDate, domainEndDate, width)
      : generateCategoricalXScale(dimensionValues, width);

    d3XAxis = generateXAxis(d3XScale, width, isUsingTimeScale);
    d3YScale = self.generateYScale(minYValue, maxYValue, height);
    d3YAxis = self.generateYAxis({
      dataToRender: timelineDataToRender,
      height,
      isSecondaryAxis: false,
      scale: d3YScale,
      series: firstNonFlyoutSeries
    });

    if (isUsingTimeScale) {
      d3AreaTimeSeries = dataToRenderBySeries.map((series, seriesIndex) => {
        const seriesTypeVariant = self.getTypeVariantBySeriesIndex(seriesIndex);

        if (seriesTypeVariant === 'line') {
          return null;
        } else {
          return (
            d3.svg
              .area()
              .defined((d) => !_.isNull(d[seriesMeasureIndex]))
              .x((d) => {
                if (allSeriesAreLineVariant()) {
                  return d3XScale(parseDate(d[seriesDimensionIndex]));
                } else if (precision !== 'none') {
                  // For area (bucketed) variants, we need to shift the rendered
                  // points by half the width of the domain interval to the right in
                  // order for the peaks to appear in the middle of the intervals,
                  // as opposed to the beginning of them (if we do not do this
                  // shift, the a range of 2001-2002 and a value of 1000 looks like
                  // the 1000 was measured on Jan 1, 2001).
                  return d3XScale(
                    new Date(
                      parseDate(d[seriesDimensionIndex]).getTime() + getSeriesHalfIntervalWidthInMs(series)
                    )
                  );
                } else {
                  return d3XScale(parseDate(d[seriesDimensionIndex]));
                }
              })
              /* eslint-disable no-unused-vars */
              .y0((d) => d3YScale(0))
              /* eslint-enable no-unused-vars */
              .y1((d) => d3YScale(d[seriesMeasureIndex]))
          );
        }
      });

      d3LineTimeSeries = dataToRenderBySeries.map((series, seriesIndex) => {
        const { horizontalAlignment } = self.getLineStyleBySeriesIndex(seriesIndex);

        return d3.svg
          .line()
          .defined((d) => !_.isNull(d[seriesMeasureIndex]))
          .x((d) => {
            if (allSeriesAreLineVariant()) {
              return d3XScale(parseDate(d[seriesDimensionIndex]));
            } else {
              if (precision !== 'none' && horizontalAlignment === 'middle') {
                // For area (bucketed) variants, we need to shift the rendered
                // points by half the width of the domain interval to the right in
                // order for the peaks to appear in the middle of the intervals,
                // as opposed to the beginning of them (if we do not do this
                // shift, the a range of 2001-2002 and a value of 1000 looks like
                // the 1000 was measured on Jan 1, 2001).
                return d3XScale(
                  new Date(
                    parseDate(d[seriesDimensionIndex]).getTime() + getSeriesHalfIntervalWidthInMs(series)
                  )
                );
              } else {
                return d3XScale(parseDate(d[seriesDimensionIndex]));
              }
            }
          })
          .y((d) => d3YScale(d[seriesMeasureIndex]));
      });
    } else {
      const halfBandWidth = Math.round(d3XScale.rangeBand() / 2.0);

      d3AreaCategoricalSeries = dataToRenderBySeries.map((series, seriesIndex) => {
        const seriesTypeVariant = self.getTypeVariantBySeriesIndex(seriesIndex);

        if (seriesTypeVariant === 'line') {
          return null;
        } else {
          return d3.svg
            .area()
            .defined((d) => !_.isNull(d[seriesMeasureIndex]))
            .x((d) => d3XScale(d[seriesDimensionIndex]) + halfBandWidth)
            .y0(() => d3YScale(0))
            .y1((d) => d3YScale(d[seriesMeasureIndex]));
        }
      });

      d3LineCategoricalSeries = dataToRenderBySeries.map(() => {
        return d3.svg
          .line()
          .defined((d) => !_.isNull(d[seriesMeasureIndex]))
          .x((d) => d3XScale(d[seriesDimensionIndex]) + halfBandWidth)
          .y((d) => d3YScale(d[seriesMeasureIndex]));
      });
    }

    // Remove any existing root svg element.
    rootElement = d3.select($chartElement[0]);
    rootElement.select('svg').remove();

    // Render a new root svg element.
    chartSvg = rootElement.append('svg');
    chartSvg
      .attr('height', viewportHeight + topMargin + bottomMargin)
      .attr('width', width + leftMargin + rightMargin);

    // Render the viewport group.
    viewportSvg = chartSvg
      .append('g')
      .attr('class', 'viewport')
      .attr('transform', `translate(${leftMargin},${topMargin})`);

    // Render the panning clip path.
    const clipPathSvg = chartSvg.append('clipPath').attr('id', panningClipPathId);

    const maskWidth = viewportWidth + leftMargin + rightMargin;
    const maskHeight = viewportHeight + topMargin + bottomMargin;
    const yAxisHeightAndTicks = height + D3_TICK_SIZE;

    const points = [
      '0,0', // top of Y-axis
      `0,${yAxisHeightAndTicks}`, // just below chart origin on Y-axis
      `${-leftMargin},${yAxisHeightAndTicks}`, // jut out to the left edge
      `${-leftMargin},${maskHeight}`, // continue to the bottom left corner
      `${maskWidth},${maskHeight}`, // bottom right corner
      `${maskWidth},0` // top right corner
    ];

    clipPathSvg.append('polygon').attr('points', points.join(' '));

    // Render the y- and x-axis.
    viewportSvg.append('g').attr('class', 'y axis');

    viewportSvg.append('g').attr('class', 'y grid');

    xAxisAndSeriesSvg = viewportSvg
      .append('g')
      .attr('class', 'x-axis-and-series')
      .attr('clip-path', `url(#${panningClipPathId})`);

    const seriesSvg = xAxisAndSeriesSvg
      .append('g')
      .attr('class', 'series')
      .attr('clip-path', `url(#${measureBoundsClipPathId})`);

    // Render the measure axes bounds clip path.
    xAxisAndSeriesSvg
      .append('clipPath')
      .attr('id', measureBoundsClipPathId)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height);

    xAxisAndSeriesSvg.append('g').attr('class', 'x axis');

    xAxisAndSeriesSvg.append('g').attr('class', 'x axis baseline');

    // Render the actual marks.
    const measureIndex = 1;
    const connectNonAdjacentPoints = _.get(self.getVif(), 'configuration.connectNonAdjacentPoints', false);
    dataToRenderBySeries.forEach((series, seriesIndex) => {
      const seriesTypeVariant = self.getTypeVariantBySeriesIndex(seriesIndex);
      const areaSvg = seriesSvg.append('path');
      const lineSvg = seriesSvg.append('path');
      const dotsGroupSvg = seriesSvg.append('g');
      const dotsSvg = dotsGroupSvg.selectAll(`.series-${seriesIndex}-line-dot`);
      const textSvg = dotsGroupSvg.selectAll(`.series-${seriesIndex}-line-text`);
      const rows = connectNonAdjacentPoints
        ? _.reject(series.rows, (row, index) => _.isNull(row[measureIndex]))
        : series.rows;

      areaSvg.datum(rows).attr('class', `series-${seriesIndex}-${seriesTypeVariant}-area`);

      lineSvg.datum(rows).attr('class', `series-${seriesIndex}-${seriesTypeVariant}-line`);

      dotsGroupSvg.attr('class', `series-${seriesIndex}-line-dots`);

      dotsSvg.data(rows).enter().append('circle');

      if (getShowValueLabels(self.getVif())) {
        textSvg.data(rows).enter().append('text').attr('class', 'text-series');
      }
    });

    renderXAxis();
    renderYAxis();

    if (isUsingTimeScale) {
      viewportSvg
        .append('rect')
        .attr('class', 'highlight')
        .attr('fill', DEFAULT_LINE_HIGHLIGHT_FILL)
        .attr('height', height)
        .attr('opacity', '0')
        .attr('stroke', 'none');

      viewportSvg
        .append('rect')
        .attr('class', 'overlay')
        .attr('fill', 'none')
        .attr('height', viewportHeight)
        .attr('stroke', 'none')
        .attr('width', viewportWidth)
        .on('mousemove', handleMouseMove(leftMargin))
        .on('mouseleave', () => {
          hideHighlight();
          hideFlyout();
        });
    } else {
      const measureValueIndex = 1;

      // NOTE: The below function depends on this being set by d3, so it is not
      // possible to use the () => {} syntax here.
      seriesSvg
        .selectAll('circle')
        .on('mousemove', function (d) {
          if (!isCurrentlyPanning()) {
            const seriesIndex = parseInt(this.getAttribute('data-series-index'), 10);
            const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

            const measure = measures[seriesIndex];
            const dimensionValue = d[0];
            const value = d[measureValueIndex];

            showCircleHighlight(this, measure);
            showCircleFlyout({
              dimensionIndex,
              dimensionValue,
              element: this,
              measureIndex: measure.measureIndex,
              measures,
              value
            });
          }
        })
        .on('mouseleave', () => {
          if (!isCurrentlyPanning()) {
            hideCircleHighlight();
            hideFlyout();
          }
        });

      chartSvg
        .selectAll('.x.axis .tick text')
        .on('mousemove', (d, dimensionIndex) => {
          if (!isCurrentlyPanning()) {
            showGroupFlyout({
              dimensionIndex,
              dimensionValue: d,
              leftMargin,
              value: d
            });
          }
        })
        .on('mouseleave', () => {
          if (!isCurrentlyPanning()) {
            hideFlyout();
          }
        });
    }

    // Render reference lines
    referenceLineSvgs = viewportSvg
      .selectAll('line.reference-line')
      .data(referenceLines)
      .enter()
      .append('line')
      .attr('class', 'reference-line');

    referenceLineUnderlaySvgs = viewportSvg
      .selectAll('rect.reference-line-underlay')
      .data(referenceLines)
      .enter()
      .append('rect')
      .attr('class', 'reference-line-underlay')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const underlayHeight = parseInt($(this).attr('height'), 10);
          const flyoutOffset = {
            left: d3.event.clientX,
            top: this.getBoundingClientRect().top + underlayHeight / 2
          };

          self.showReferenceLineFlyout({
            dataToRender: timelineDataToRender,
            element: this,
            flyoutOffset,
            referenceLines
          });

          $(this).attr('fill-opacity', 1);
        }
      })
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mouseleave', function () {
        if (!isCurrentlyPanning()) {
          hideFlyout();
          $(this).attr('fill-opacity', 0);
        }
      });

    function onMouseMoveAnnotation(d) {
      const rawDate = _.get(d, ANNOTATIONS.DATE_INDEX);
      const formattedDate = formatDateForFlyout(rawDate);
      const description = _.get(d, ANNOTATIONS.DESCRIPTION_INDEX);
      const precision = _.get(self.getVif(), 'series[0].dataSource.precision');
      const bisectorIndex = d3.bisectLeft(bisectorDates, rawDate);
      const dimensionIndex = _.clamp(
        precision !== 'none' ? bisectorIndex - 1 : bisectorIndex,
        0,
        timelineDataToRender.rows.length - 1
      );

      const startDate = parseDate(timelineDataToRender.rows[dimensionIndex][0]);
      const incrementedStartDate = getIncrementedDateByPrecision(startDate, timelineDataToRender.precision);
      const endDate = new Date(moment(incrementedStartDate).subtract(1, 'millisecond').format());

      showHighlight(startDate, endDate);
      showAnnotationFlyout({ date: formattedDate, description, element: this });
    }

    // All annotations that are within an annotation markers circumference are grouped together,
    // so that we can set an offset to their positions and plot them on the chart to make them uncluttered.
    _.each(annotationsToRender, (annotation) => {
      const [date] = annotation;
      const currentXScale = _.round(d3XScale(date));
      const startXScale = currentXScale - ANNOTATIONS.CIRCLE_RADIUS;
      const endXScale = currentXScale + ANNOTATIONS.CIRCLE_RADIUS;
      const filteredXScale = _.filter(annotationsXAxisValues, (xAxisValue) => {
        return xAxisValue > startXScale && xAxisValue < endXScale;
      });

      const xScale = _.isEmpty(filteredXScale) ? currentXScale : _.min(filteredXScale);

      annotationsXAxisValues.push(xScale);
    });
    annotationsSvg = viewportSvg
      .append('g')
      .attr('class', 'annotations')
      .attr('clip-path', `url(#${panningClipPathId})`);

    annotationDotsSvg = annotationsSvg
      .selectAll('circle.annotation-dots')
      .data(annotationsToRender)
      .enter()
      .append('circle')
      .on('mousemove', onMouseMoveAnnotation)
      .on('mouseleave', function (d) {
        hideFlyout();
      });

    annotationTextSvg = annotationsSvg
      .selectAll('text.annotation-text')
      .data(annotationsToRender)
      .enter()
      .append('text');
    renderAnnotations();

    renderValues();
    renderReferenceLines();

    labelResizer.update(leftMargin, topMargin + height, width);

    if (xAxisPanningEnabled) {
      d3Zoom = d3.behavior.zoom().on('zoom', handleZoom);

      viewportSvg
        .attr('cursor', 'move')
        .call(d3Zoom)
        // By default the zoom behavior seems to capture every conceivable kind
        // of zooming action; we actually just want it to zoom when the user
        // clicks and drags, so we need to immediately deregister the event
        // handlers for the other types.
        //
        // Note that although we listen for the zoom event on the zoom behavior
        // we must detach the zooming actions we do not want to respond to from
        // the element to which the zoom behavior is attached.
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        .on('mousewheel.zoom', null)
        .on('MozMousePixelScroll.zoom', null);

      restoreLastRenderedZoom();

      SvgKeyboardPanning(d3Zoom, chartSvg, '.socrata-visualization-panning-notice');

      chartSvg.selectAll('text').attr('cursor', null);
    }

    self.renderAxisLabels(chartSvg, {
      x: leftMargin,
      y: topMargin,
      width: viewportWidth,
      height: viewportHeight
    });
  }

  function isCurrentlyPanning() {
    // EN-10810 - Bar Chart flyouts do not appear in Safari
    //
    // Internet Explorer will apparently always return a non-zero value for
    // d3.event.which and even d3.event.button, so we need to check
    // d3.event.buttons for a non-zero value (which indicates that a button is
    // being pressed).
    //
    // Safari apparently does not support d3.event.buttons, however, so if it
    // is not a number then we will fall back to d3.event.which to check for a
    // non-zero value there instead.
    //
    // Chrome appears to support both cases, and in the conditional below
    // Chrome will check d3.event.buttons for a non-zero value.
    return _.isNumber(d3.event.buttons) ? d3.event.buttons !== 0 : d3.event.which !== 0;
  }

  // Returns one half of the interval between the first and second data in the
  // series, in milliseconds. This is used to offset points representing
  // bucketed data so that the points fall in the middle of the bucketed
  // interval, as opposed to at its start.
  // If there is only a single point, it will increment the date by one precision
  // to get the offset.
  function getSeriesHalfIntervalWidthInMs(series) {
    const seriesDimensionIndex = 0;
    const startDate = parseDate(series.rows[0][seriesDimensionIndex]);
    const endDate =
      series.rows.length <= 1
        ? getIncrementedDateByPrecision(startDate, timelineDataToRender.precision)
        : parseDate(series.rows[1][seriesDimensionIndex]);

    return (endDate.getTime() - startDate.getTime()) / 2;
  }

  function allSeriesAreLineVariant() {
    return _.get(self.getVif(), 'series', [])
      .map((series, seriesIndex) => {
        return self.getTypeVariantBySeriesIndex(seriesIndex);
      })
      .every((type) => type === 'line');
  }

  function handleMouseMove(leftMargin) {
    return function doHandleMouseMove() {
      const precision = _.get(self.getVif(), 'series[0].dataSource.precision');
      const rawDate = d3XScale.invert(d3.mouse(this)[0] - xAxisPanDistanceFromZoom);
      const bisectorIndex = d3.bisectLeft(bisectorDates, rawDate);

      const dimensionIndex = _.clamp(
        precision !== 'none' ? bisectorIndex - 1 : bisectorIndex,
        0,
        timelineDataToRender.rows.length - 1
      );

      const dimensionValueIndex = 0;
      let xOffset;

      const startDate = parseDate(timelineDataToRender.rows[dimensionIndex][dimensionValueIndex]);
      const incrementedStartDate = getIncrementedDateByPrecision(startDate, timelineDataToRender.precision);
      const endDate = new Date(moment(incrementedStartDate).subtract(1, 'millisecond').format());

      if (allSeriesAreLineVariant()) {
        xOffset = d3XScale(startDate);
      } else {
        if (precision !== 'none') {
          // For area (bucketed) variants, we need to shift the rendered points by
          // half the width of the domain interval to the right in order for the
          // peaks to appear in the middle of the intervals, as opposed to the
          // beginning of them (if we do not do this shift, the a range of 2001-2002
          // and a value of 1000 looks like the 1000 was measured on Jan 1, 2001).
          xOffset = d3XScale(
            new Date(startDate.getTime() + getSeriesHalfIntervalWidthInMs(dataToRenderBySeries[0]))
          );
        } else {
          xOffset = d3XScale(startDate);
        }
      }

      const row = timelineDataToRender.rows[dimensionIndex].slice(1);
      const data = _.map(row, (value, index) => {
        let label = timelineDataToRender.columns[index + 1];
        // We do not want to apply formatting if the label is `(Other)` category
        if (!_.isEqual(label, I18n.t('shared.visualizations.charts.common.other_category'))) {
          const groupingColumn = _.get(self.getVif(), 'series[0].dataSource.dimension.grouping.columnName');
          label = _.isNil(groupingColumn)
            ? label
            : formatValuePlainText(label, groupingColumn, timelineDataToRender, true);
        }
        return {
          label,
          value
        };
      });

      const payload = {
        data,
        dimensionIndex,
        leftMargin,
        startDate,
        xOffset
      };

      if (precision !== 'none') {
        payload.endDate = endDate;
      }

      showHighlight(startDate, endDate);
      showFlyout(payload);
    };
  }

  function showCircleHighlight(circleElement, measure) {
    d3.select(circleElement).attr('fill', measure.getColor());
  }

  function hideCircleHighlight() {
    const lineStyle = self.getLineStyleBySeriesIndex(0);
    if (lineStyle.points !== 'closed') {
      // NOTE: The below function depends on this being set by d3, so it is not
      // possible to use the () => {} syntax here.
      d3.select($chartElement[0])
        .selectAll('circle')
        .each(function () {
          const selection = d3.select(this);
          selection.attr('fill', selection.attr('data-default-fill'));
        });
    }
  }

  function showHighlight(startDate, endDate) {
    const scaledStartDate = d3XScale(startDate);
    const scaledEndDate = d3XScale(endDate);
    const rootElement = d3.select($chartElement[0]);

    let highlightWidth = Math.max(MINIMUM_HIGHLIGHT_WIDTH, scaledEndDate - scaledStartDate - 2);
    let highlightXTranslation;

    if (precision !== 'none') {
      highlightXTranslation = allSeriesAreLineVariant()
        ? scaledStartDate - highlightWidth / 2
        : scaledStartDate;
    } else {
      highlightXTranslation = scaledStartDate - highlightWidth / 2;
    }

    // If we are offsetting the highlight left by half of its width but that
    // would place it beyond the y-axis, then start the highlight at the y-axis
    // and only render half of it. This will cause it to appear as if it has
    // been truncated by the y-axis.
    if (highlightXTranslation < 0) {
      highlightWidth = highlightWidth / 2;
      highlightXTranslation = 0;
    }

    highlightXTranslation += xAxisPanDistanceFromZoom;

    rootElement
      .select('.highlight')
      .attr('display', 'block')
      .attr('width', highlightWidth)
      .attr('transform', `translate(${highlightXTranslation},0)`);
  }

  function hideHighlight() {
    const rootElement = d3.select($chartElement[0]);

    rootElement.select('.highlight').attr('display', 'none');
  }

  function showAnnotationFlyout({ element, date, description }) {
    const $content = self.getAnnotationFlyoutContent({ date, description });

    // Payload
    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', payload);
  }

  function showCircleFlyout({ element, dimensionIndex, dimensionValue, measureIndex, measures, value }) {
    const $content = self.getFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measureIndex,
      measures,
      nonFlyoutDataToRender: timelineDataToRender,
      value
    });

    // Payload
    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', payload);
  }

  function showGroupFlyout({ dimensionIndex, dimensionValue, leftMargin, value }) {
    const hideNullsInFlyout = _.get(self.getVif(), 'configuration.hideNullsInFlyout', false);
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      hideNullsInFlyout,
      measures,
      nonFlyoutDataToRender: timelineDataToRender
    });

    // Positioning
    const boundingClientRect = self.$element.find('.timeline-chart')[0].getBoundingClientRect();

    const flyoutXOffset = d3XScale(value);
    let maxFlyoutValueOffset;

    if (minYValue <= 0 && maxYValue >= 0) {
      maxFlyoutValueOffset = d3YScale(0);
    } else if (maxYValue < 0) {
      maxFlyoutValueOffset = d3YScale(maxYValue);
    } else {
      maxFlyoutValueOffset = d3YScale(minYValue);
    }

    const parts = $('.x-axis-and-series')
      .css('transform')
      .replace(/[^0-9\-.,]/g, '')
      .split(',');
    const translateXOffset = parseInt(parts[4], 10) || 0; // X translation when panned
    const halfBandWidth = Math.round(d3XScale.rangeBand() / 2);

    // Payload
    const payload = {
      belowTarget: false,
      content: $content,
      dark: true,
      flyoutOffset: {
        left: boundingClientRect.left + leftMargin + flyoutXOffset + halfBandWidth + translateXOffset,
        top: boundingClientRect.top + MARGINS.TOP + maxFlyoutValueOffset
      },
      rightSideHint: false
    };

    self.emitEvent('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', payload);
  }

  function formatDateForFlyout(datetime) {
    const year = datetime.getFullYear();
    const month = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.'
    ][datetime.getMonth()];
    const date = datetime.getDate();
    const translatedMonth = getTranslation(month);

    return `${translatedMonth} ${date}, ${year}`;
  }

  function showFlyout({ data, dimensionIndex, endDate, leftMargin, startDate, xOffset }) {
    let title;

    if (_.isNil(endDate)) {
      const dimensionColumn = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
      const value = startDate.toISOString();
      title = formatValuePlainText(value, dimensionColumn, timelineDataToRender);
    } else if (allSeriesAreLineVariant()) {
      title = formatDateForFlyout(startDate);
    } else {
      const formattedStartDate = formatDateForFlyout(startDate);
      const formattedEndDate = formatDateForFlyout(endDate);
      title = `${formattedStartDate} to ${formattedEndDate}`;
    }

    const hideNullsInFlyout = _.get(self.getVif(), 'configuration.hideNullsInFlyout', false);
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      flyoutDataToRender,
      hideNullsInFlyout,
      measures,
      nonFlyoutDataToRender: timelineDataToRender,
      title
    });

    // Note: d3.max will return undefined if passed an array of non-numbers
    // (such as when we try to show a flyout for a null value).
    const maxFlyoutValue = _.clamp(d3.max(data.map((datum) => datum.value)), minYValue, maxYValue);

    // Positioning
    let maxFlyoutValueOffset;

    if (_.isNumber(maxFlyoutValue)) {
      maxFlyoutValueOffset = d3YScale(maxFlyoutValue);
    } else if (minYValue <= 0 && maxYValue >= 0) {
      maxFlyoutValueOffset = d3YScale(0);
    } else if (maxYValue < 0) {
      maxFlyoutValueOffset = d3YScale(maxYValue);
    } else {
      maxFlyoutValueOffset = d3YScale(minYValue);
    }

    const boundingClientRect = self.$element.find('.timeline-chart')[0].getBoundingClientRect();

    // Payload
    const payload = {
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      flyoutOffset: {
        left: boundingClientRect.left + leftMargin + xOffset + lastRenderedZoomTranslate,
        top: boundingClientRect.top + MARGINS.TOP + maxFlyoutValueOffset
      },
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', payload);
  }

  function hideFlyout() {
    self.emitEvent('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', null);
  }

  function getIncrementedDateByPrecision(date, precision) {
    const nextDate = _.clone(date);

    if (!_.isNil(precision)) {
      switch (precision) {
        case 'year':
          nextDate.setFullYear(nextDate.getFullYear() + 1);
          break;

        case 'quarter':
          nextDate.setMonth(nextDate.getMonth() + 3);
          break;

        case 'month':
          nextDate.setMonth(nextDate.getMonth() + 1);
          break;

        case 'week':
          nextDate.setDate(nextDate.getDate() + 7);
          break;

        case 'day':
          nextDate.setDate(nextDate.getDate() + 1);
          break;

        default:
          throw new Error(`Unknown precision: ${precision} - unable to increment date.`);
      }
    }

    return nextDate;
  }

  function generateXAxis(xScale, width, isUsingTimeScale) {
    const xAxis = d3.svg.axis().scale(xScale).orient('bottom');

    if (isUsingTimeScale) {
      // Display a tick every 150px or so for the non-bucketed timeline.
      const ticks = Math.floor(width / RECOMMENDED_TICK_DISTANCE);

      xAxis.ticks(ticks).tickFormat((date) => {
        return getTranslation(xScale.tickFormat()(date));
      });
    } else {
      const columnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
      xAxis.tickFormat((d) =>
        self.getColumnFormattedValueText({
          columnName,
          dataToRender: timelineDataToRender,
          value: d
        })
      );
    }

    return xAxis;
  }

  function generateTimeXScale(domainStartDate, domainEndDate, width) {
    let startAnnotationDate;
    let endAnnotationDate;
    const filters = _.get(self.getVif(), 'series[0].dataSource.filters');
    const dimensionColumnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
    const dimensionColumnFilter = _.find(filters, function (filter) {
      return filter.columns[0].fieldName === dimensionColumnName;
    });
    if (_.isEmpty(_.get(dimensionColumnFilter, 'arguments'))) {
      const annotationDates = _.map(annotationsToRender, (annotation) => {
        return _.get(annotation, ANNOTATIONS.DATE_INDEX);
      });
      annotationDates.push(domainStartDate);
      annotationDates.push(domainEndDate);

      startAnnotationDate = new Date(Math.min.apply(null, annotationDates));
      endAnnotationDate = new Date(Math.max.apply(null, annotationDates));
    } else {
      startAnnotationDate = domainStartDate;
      endAnnotationDate = domainEndDate;
    }

    const lineStyle = self.getLineStyleBySeriesIndex(0);
    const pointRadius = _.isFinite(lineStyle.pointRadius) ? lineStyle.pointRadius : 0;

    const startLabelsMaxWidth = getValueLabelMaxWidth({ rowIndex: 0 });
    const startLabelsPadding = Math.ceil(startLabelsMaxWidth / 2);
    const startPadding = Math.max(pointRadius, DEFAULT_CIRCLE_HIGHLIGHT_RADIUS, startLabelsPadding);

    const endLabelsMaxWidth = getValueLabelMaxWidth({ rowIndex: timelineDataToRender.rows.length - 1 });
    const endLabelsPadding = Math.ceil(endLabelsMaxWidth / 2);
    const endPadding = Math.max(pointRadius, DEFAULT_CIRCLE_HIGHLIGHT_RADIUS, endLabelsPadding);

    // Move the timescale domain outward on both ends to be able to display
    // the full data point circle and value label (if shown) without clipping.
    const milliseconds = endAnnotationDate - startAnnotationDate;
    const millisecondsForStartPadding = (milliseconds * startPadding) / width;
    const millisecondsForEndPadding = (milliseconds * endPadding) / width;

    const startDate = moment(startAnnotationDate).add(-millisecondsForStartPadding, 'milliseconds');
    const endDate = moment(endAnnotationDate).add(millisecondsForEndPadding, 'milliseconds');

    return d3.time.scale().domain([startDate, endDate]).range([0, width]);
  }

  function generateCategoricalXScale(domain, width) {
    return d3.scale.ordinal().domain(domain).rangeRoundBands([0, width], 0.1, 0.05);
  }

  function getValueLabelMaxWidth({ rowIndex }) {
    return getShowValueLabels(self.getVif())
      ? _.chain(timelineDataToRender.rows[rowIndex])
          .slice(1) // slice off dimension value at index 0
          .map(
            (
              label // get widths of each value label
            ) => calculateTextSize(FONT_STACK, DATA_POINT_LABELS_FONT_SIZE, label).width
          )
          .max() // get max width
          .value()
      : 0;
  }

  function isDimensionCalendarDate(dimensionColumnName, columnFormats) {
    const columnFormat = columnFormats[dimensionColumnName];
    return !_.isUndefined(columnFormat) && columnFormat.dataTypeName === 'calendar_date';
  }

  // Gets the midpoint between each date for use as bisector dates for non-bucketed data
  //
  function getPrecisionNoneBisectorDates(rows) {
    let previousDate;
    const dates = [];

    rows.forEach((row) => {
      if (_.isNil(row[0])) {
        return;
      }

      let currentDate = parseDate(row[0]); // first index is the dimension data value

      if (!_.isNil(previousDate)) {
        dates.push(new Date((previousDate.getTime() + currentDate.getTime()) / 2));
      }

      previousDate = currentDate;
    });

    // Push a date after the last date to be the final bisector date so that we may select
    // the last data point.
    //
    if (!_.isNil(previousDate)) {
      let lastDate = new Date(previousDate.getTime());
      lastDate.setFullYear(lastDate.getFullYear() + 1); // doesn't matter how far in the future after the last date in row
      dates.push(lastDate);
    }

    return dates;
  }
}

export default SvgTimelineChart;
