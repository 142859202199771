import { get, find, isEmpty } from 'lodash';
import { View } from 'common/types/view';
import { ICellRendererParams } from '@ag-grid-community/core';

export interface ContextParams {
  primaryKeyColumn: string;
  contextName: string | undefined;
  mediaType: string | undefined;
}

type PrimaryKeyValue = string | number;

export interface ApplicationDescriptor {
  id: string;
  url: string;
}

export const defaultProcessDiscoveryContext: ContextParams = {
  primaryKeyColumn: '',
  contextName: '',
  mediaType: ''
};

export const defaultLinkedAction = {
  selectedExistingColumn: '',
  selectedActionForExistingColumn: ''
};

export const getProcessDiscoveryContext = (viewMetadata: View): ContextParams => {
  if (isEmpty(viewMetadata)) {
    return defaultProcessDiscoveryContext;
  }
  const { metadata, columns } = viewMetadata;
  const rowIdentifierColumnId = get(viewMetadata, 'rowIdentifierColumnId');
  const primaryColumnConfig = columns.find((column) => column.id === rowIdentifierColumnId);

  const primaryKeyColumn = get(primaryColumnConfig, 'fieldName', '');
  const munisContext = find(get(metadata, 'custom_fields', {}), (__, fieldKey) => {
    return fieldKey === 'MUNIS Context Metadata';
  });
  const { 'Context Name': contextName, 'Media Type': mediaType } = munisContext || {};
  return {
    primaryKeyColumn,
    contextName,
    mediaType
  };
};

export const getPrimaryKeyValue = (
  rowParams: ICellRendererParams,
  primaryKeyColumn: string
): PrimaryKeyValue => {
  const { data, node } = rowParams || {};

  const primaryKeyValue = get(data, primaryKeyColumn, '') || get(node, 'data.' + primaryKeyColumn);
  if (primaryKeyValue) {
    return primaryKeyValue;
  } else if (node && node.parent) {
    const { parent } = node;
    return getPrimaryKeyValue(
      { ...rowParams, node: { ...parent, group: true }, data: parent.data },
      primaryKeyColumn
    );
  } else {
    return '';
  }
};
