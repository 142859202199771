// src/components/PdfPreviewModal/index.tsx
import React from 'react';
import { GridApi } from '@ag-grid-community/core';
import { ForgeIcon, ForgeDialog, ForgeToolbar, ForgeIconButton } from '@tylertech/forge-react';

import { OrderConfig } from 'common/visualizations/vif';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { Dataset, ReportMetadata } from './types';
import { PreviewPane } from './PreviewPane';
import './PdfPreviewModal.scss';

interface PdfPreviewModalProps {
  dataset: Dataset;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
  gridApi: GridApi | null;
  onClose: () => void;
  reportMetadata: ReportMetadata;
  nonStandardAggregations: ColumnAggregation[] | null;
  columnFormats: { [key: string]: TableColumnFormat };
  vifOrderConfig: OrderConfig[];
  showSubTotal: boolean;
  showTotal: boolean;
}

export const PdfPreviewModal: React.FC<PdfPreviewModalProps> = ({
  dataset,
  getExportData,
  gridApi,
  onClose,
  reportMetadata,
  columnFormats,
  nonStandardAggregations,
  vifOrderConfig,
  showSubTotal,
  showTotal
}) => {
  const dialogProps = { fullscreen: true };

  return (
    <ForgeDialog open={true} options={dialogProps} onDismiss={onClose}>
      <div className="pdf-preview-modal">
        <div className="preview-modal-header">
          <h2>Print Preview</h2>
          <ForgeIconButton slot="end">
            <button type="button" aria-label="Close Print Preview" onClick={onClose}>
              <ForgeIcon name="close"></ForgeIcon>
            </button>
          </ForgeIconButton>
        </div>
        <div className="pdf-preview-body">
          {gridApi && getExportData && (
            <PreviewPane
              dataset={dataset}
              getExportData={getExportData}
              gridApi={gridApi}
              nonStandardAggregations={nonStandardAggregations}
              columnFormats={columnFormats}
              reportMetadata={reportMetadata}
              showSubTotal={showSubTotal}
              showTotal={showTotal}
              vifOrderConfig={vifOrderConfig}
            />
          )}
        </div>
      </div>
    </ForgeDialog>
  );
};
