import React from 'react';
import { ForgeSlider } from '@tylertech/forge-react';

import { PreviewSettings } from '../types';
import { PRINT_PREVIEW_FONT_SLIDER_CONFIG } from '../templates/section/defaultStyles';

interface FontSizeSliderProps {
  fontSize: number;
  onChange: <K extends keyof PreviewSettings>(key: K, value: PreviewSettings[K]) => void;
}

const FontSizeSlider = ({ fontSize, onChange }: FontSizeSliderProps) => {
  const handleSliderChange = (e: CustomEvent) => {
    onChange('fontSize', e.detail.value);
  };

  return (
    <div className="font-size-slider">
      <ForgeSlider
        role="forge-slider"
        style={{ width: '100%' }}
        type="continuous"
        min={PRINT_PREVIEW_FONT_SLIDER_CONFIG.MIN}
        max={PRINT_PREVIEW_FONT_SLIDER_CONFIG.MAX}
        value={fontSize}
        step={PRINT_PREVIEW_FONT_SLIDER_CONFIG.STEP}
        on-forge-slider-change={handleSliderChange}
      />
    </div>
  );
};

export default FontSizeSlider;
