import { ForgeIcon, ForgeRadio, ForgeInlineMessage } from '@tylertech/forge-react';
import React, { FormEvent, FunctionComponent } from 'react';
import { shouldRenderWarning } from './ExportHelper';
import { ResourceState } from './ExportModalTypes';
import { fetchTranslation } from 'common/locale';
import { isEmpty } from 'lodash';

export interface FilterRadioButtonProps {
  resourceState: ResourceState;
  selectedFiltered: boolean;
  handleRadioFilterChange: (selectedFiltered: boolean) => void;
  showFilteredDataOption: boolean;
  rowCountFiltered?: number;
  rowCountAll?: number;
  odata?: boolean;
}

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');
const translationWithOption = (k: string, option: any) =>
  fetchTranslation(k, 'shared.explore_grid.export_dataset_modal', undefined, option);

const FilterRadioButtons: FunctionComponent<FilterRadioButtonProps> = ({
  resourceState,
  selectedFiltered,
  rowCountFiltered,
  rowCountAll,
  odata = false,
  showFilteredDataOption,
  handleRadioFilterChange
}) => {
  const selectedResourceValue = resourceState?.selectedDownloadResource?.value;

  const onHandleRadioFilterChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    const isSelectedFiltered = target.value !== 'all';
    handleRadioFilterChange(isSelectedFiltered);
  };

  const maybeSelectedFiltered = odata ? false : selectedFiltered;

  return (
    <div>
      {shouldRenderWarning(selectedResourceValue, maybeSelectedFiltered, rowCountFiltered, rowCountAll) && (
        <ForgeInlineMessage theme="warning" className="partial-export-message">
          <ForgeIcon name="warning" slot="icon" />
          <div className="message-title" slot="title">
            {t('partial_export_title')}
          </div>
          <div>{t('partial_export_body')}</div>
        </ForgeInlineMessage>
      )}
      <div
        className="filtered-radio-group"
        data-testid="radio-group-filtering"
        role="radiogroup"
        aria-label={t('dialog_radio')}
        onChange={onHandleRadioFilterChange}
      >
        {showFilteredDataOption && !odata && (
          <ForgeRadio className="filtered-data">
            <input
              type="radio"
              id="radio-filtered"
              name="radio-filters"
              data-testid="radio-option-filtered"
              value="filtered"
              defaultChecked={selectedFiltered}
            />
            <label htmlFor="radio-filtered">
              {isEmpty(window.STORY_DATA)
                ? translationWithOption('modified_data', { rowCount: rowCountFiltered })
                : translationWithOption('filtered_data', { rowCount: rowCountFiltered })}
            </label>
          </ForgeRadio>
        )}
        <ForgeRadio className="all-data">
          <input
            type="radio"
            id="radio-all"
            name="radio-filters"
            data-testid="radio-option-all"
            value="all"
            defaultChecked={!selectedFiltered}
          />
          <label htmlFor="radio-all">{translationWithOption('all_data', { rowCount: rowCountAll })}</label>
        </ForgeRadio>
      </div>
    </div>
  );
};

export default FilterRadioButtons;
