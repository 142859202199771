import _ from 'lodash';
import { OrderConfig } from 'common/visualizations/vif';
import { HeaderRow, NormalRow, Row, SubTotalRow } from './type';
import { aggregateRows } from './gridDataAggregator';
import { TableColumn } from '../types';

export const groupRows = (
  rows: NormalRow[],
  tableColumns: TableColumn[],
  orderConfigs: OrderConfig[] = [],
  showSubTotal = false
): Row[] => {
  const groupByFields = tableColumns.filter((c) => c.isGrouped).map((c) => c.field);
  const sortColumns = _.map(orderConfigs, 'columnName');
  const sortOrders = _.map(orderConfigs, (s) => (s.ascending ? 'asc' : 'desc'));
  const sortedRows = sortOrders.length > 0 ? _.orderBy(rows, sortColumns, sortOrders) : rows;

  if (groupByFields.length <= 0) return sortedRows;

  const processGrouping = (childRows: Row[], currentGroupByFields: string[]): Row[] => {
    const result = _.chain(childRows)
      .groupBy((row) => _.chain(row).pick(currentGroupByFields).values().value().join('-'))
      .map((groupedRows) => {
        const groupBys = _.pick(groupedRows[0], currentGroupByFields);
        const aggregations = aggregateRows(groupedRows, tableColumns);
        return {
          ...groupBys,
          ...aggregations,
          _headerRow: buildHeaderRow(groupBys, currentGroupByFields, aggregations),
          _rows: groupedRows,
          _subTotalRow: buildSubTotalRow(groupBys, aggregations, currentGroupByFields)
        };
      })
      .orderBy(sortColumns, sortOrders)
      .flatMap((group) => [group._headerRow, ...group._rows, ...(showSubTotal ? [group._subTotalRow] : [])])
      .value();

    if (currentGroupByFields.length <= 1) return result;
    return processGrouping(result, currentGroupByFields.slice(0, -1));
  };

  return processGrouping(sortedRows, groupByFields);
};

const buildHeaderRow = (
  groupBys: Partial<NormalRow>,
  groupByFields: string[],
  aggregations: object
): HeaderRow => ({
  ...groupBys,
  ...aggregations,
  _rowType: 'header',
  _groupLevel: groupByFields.length - 1
});

const buildSubTotalRow = (
  groupBys: Partial<NormalRow>,
  aggregations: Partial<NormalRow>,
  groupByFields: string[]
): SubTotalRow => ({
  ...groupBys,
  ...aggregations,
  _rowType: 'subTotal',
  _groupLevel: groupByFields.length - 1
});
