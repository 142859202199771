import { FontStyle, Styles } from 'jspdf-autotable';

// NOTE: https://www.npmjs.com/package/jspdf-autotable Readme:
// Styles work similar to css and can be overridden by more specific styles. Overriding order:
// - Theme styles
// - styles
// - headStyles, bodyStyles and footStyles
// - alternateRowStyles
// - columnStyles
// On top of the above, we override styles for group header/subTotal/total using didCellRender

export const PRINT_PREVIEW_DEFAULT_FONT = 'helvetica';
export const DEFAULT_LINE_HEIGHT = 5;
export const PRINT_PREVIEW_FONT_SIZE_CONFIG = {
  DEFAULT: 9,
  DEFAULT_HEADER: 8,
  DEFAULT_FOOTER: 8,
  HEADER: 10,
  FOOTER: 10,
  BODY: 9,
  TOTAL: 11,
  FILTERS: 10,
  DESCRIPTION: 10,
  TITLE: 18,
  FIRST_PAGE: 12
};
export const PRINT_PREVIEW_FONT_STYLE_CONFIG: Record<string, FontStyle> = {
  BOLD: 'bold',
  NORMAL: 'normal'
};
export const PRINT_PREVIEW_CELL_PADDING_CONFIG = {
  DEFAULT: 2,
  HEADER: 3,
  SUB_TOTAL: 3,
  TOTAL: 4
};

export const PRINT_PREVIEW_FONT_SLIDER_CONFIG = {
  MIN: 1,
  MAX: 10,
  STEP: 1,
  DEFAULT: 5
};

export const DEFAULT_STYLES: Partial<Styles> = {
  font: PRINT_PREVIEW_DEFAULT_FONT,
  fontSize: PRINT_PREVIEW_FONT_SIZE_CONFIG.DEFAULT,
  cellPadding: PRINT_PREVIEW_CELL_PADDING_CONFIG.DEFAULT,
  lineWidth: 0.1,
  minCellHeight: 1,
  overflow: 'ellipsize'
};
export const DEFAULT_BODY_STYLES: Partial<Styles> = {
  minCellHeight: 1
};
export const DEFAULT_HEAD_STYLES: Partial<Styles> = {
  fontStyle: PRINT_PREVIEW_FONT_STYLE_CONFIG.BOLD,
  fontSize: PRINT_PREVIEW_FONT_SIZE_CONFIG.HEADER,
  cellPadding: 1,
  minCellHeight: 1
};
export const DEFAULT_GROUP_HEADER_STYLES: Partial<Styles> = {
  fontSize: PRINT_PREVIEW_FONT_SIZE_CONFIG.HEADER,
  minCellHeight: 1,
  cellPadding: PRINT_PREVIEW_CELL_PADDING_CONFIG.HEADER,
  fontStyle: PRINT_PREVIEW_FONT_STYLE_CONFIG.BOLD
};
export const DEFAULT_GROUP_SUB_TOTAL_STYLES: Partial<Styles> = {
  fontStyle: PRINT_PREVIEW_FONT_STYLE_CONFIG.BOLD,
  cellPadding: PRINT_PREVIEW_CELL_PADDING_CONFIG.SUB_TOTAL
};
export const DEFAULT_GROUP_TOTAL_STYLES: Partial<Styles> = {
  fontStyle: PRINT_PREVIEW_FONT_STYLE_CONFIG.BOLD,
  fontSize: PRINT_PREVIEW_FONT_SIZE_CONFIG.TOTAL,
  cellPadding: PRINT_PREVIEW_CELL_PADDING_CONFIG.TOTAL
};

export const DEFAULT_DETAIL_TOTAL_STYLES: Partial<Styles> = {
  fontStyle: PRINT_PREVIEW_FONT_STYLE_CONFIG.BOLD,
  fontSize: PRINT_PREVIEW_FONT_SIZE_CONFIG.TOTAL,
  cellPadding: PRINT_PREVIEW_CELL_PADDING_CONFIG.TOTAL
};
