import React from 'react';
import { ForgeInlineMessage, ForgeCircularProgress } from '@tylertech/forge-react';
import { AG_SOQL_EXPORT_QUERY_LIMIT } from 'common/visualizations/helpers/TableDataHelpers';
import { PREVIEW_STATUS } from './index';

interface PDFPreviewProps {
  pdfData: string | null;
  status: PREVIEW_STATUS;
}

const renderMessage = (theme: string, message: string, isLoading = false) => {
  return (
    <div className="message-container">
      <ForgeInlineMessage theme={theme}>
        <div className="message-wrapper">
          {isLoading ? <ForgeCircularProgress /> : null}
          {message}
        </div>
      </ForgeInlineMessage>
    </div>
  );
};

export const PDFPreview: React.FC<PDFPreviewProps> = ({ pdfData, status }) => {
  if (status === 'initializing' || status === 'loading' || status === 'working') {
    return renderMessage('info-primary', 'Generating preview.', true);
  }
  const formattedExportQueryLimit = new Intl.NumberFormat('en-US').format(AG_SOQL_EXPORT_QUERY_LIMIT);

  if (status === 'error') {
    return renderMessage('danger', 'Error generating preview. Please try again later.');
  }

  if (status === 'tooManyRows') {
    return renderMessage(
      'danger',
      `PDFs are limited to ${formattedExportQueryLimit} rows. ` +
        'Please add filters to render a more specific table or export data using a different format.'
    );
  }

  if (!pdfData) {
    return renderMessage('danger', 'No preview available');
  }

  return <iframe src={pdfData} width="100%" height="100%" style={{ border: 'none' }} title="PDF Preview" />;
};
