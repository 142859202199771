import { ForgeDialog } from '@tylertech/forge-react';
import React from 'react';

import './custom-sort.scss';
import { ForgeScaffold } from '@tylertech/forge-react';
import { ForgeToolbar } from '@tylertech/forge-react';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeSelect } from '@tylertech/forge-react';
import { ViewColumn } from 'common/types/viewColumn';
import { map } from 'lodash';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { getAgTableHeaderName } from '../../helpers/TableColumnFormatting';
import I18n from 'common/i18n';

const scope = 'shared.components.custom_sort.modal';

interface CustomSortModalProps {
  isCustomSortModalOpen: boolean;
  setIsCustomSortModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  columnsToCustomSort: ViewColumn[];
  columnFormats: { [key: string]: TableColumnFormat };
}

const CustomSortModal: React.FC<CustomSortModalProps> = ({
  isCustomSortModalOpen,
  setIsCustomSortModalOpen,
  columnsToCustomSort,
  columnFormats
}) => {
  const hideModal = () => {
    setIsCustomSortModalOpen(false);
  };

  const handleReset = () => {
    // TODO: EN-75876 add reset logic here
  };

  const handleApply = () => {
    // TODO: EN-75852 add apply logic here
  };

  const dialogAttributes = {
    'aria-labelledby': 'custom-sort-dialog',
    'aria-describedby': 'custom-sort-dialog',
    backdropClose: false
  };

  const mappedColumnsToCustomSort = map(columnsToCustomSort, (col) => {
    const columnFormat = columnFormats?.[col.fieldName];
    const displayName = getAgTableHeaderName(columnFormat, col);
    return {
      label: displayName,
      value: col.fieldName
    };
  });

  const initialColumnToSort =
    mappedColumnsToCustomSort.length > 0 ? mappedColumnsToCustomSort[0].value : null;

  const selectAttributes = {
    'data-testid': 'custom-sort-dialog-dropdown',
    options: mappedColumnsToCustomSort,
    value: initialColumnToSort,
    label: I18n.t('dropdown_label', { scope }),
    floatLabelType: 'always',
    onChange: (_event: CustomEvent) => {
      // TODO: EN-75851 add `on-select-column` logic here
    }
  };

  return (
    <ForgeDialog onDismiss={hideModal} open={isCustomSortModalOpen} options={dialogAttributes}>
      <div data-testid="custom-sort-dialog">
        <ForgeScaffold className="custom-sort-scaffold">
          <ForgeToolbar slot="header" no-divider>
            <h1 id="dialog-title" slot="start" className="forge-typography--title">
              {I18n.t('title', { scope })}
            </h1>
          </ForgeToolbar>
          <div slot="body" className="custom-sort-modal-body">
            <ForgeSelect {...selectAttributes} />
          </div>
          <ForgeToolbar slot="footer" no-divider>
            <ForgeButton slot="start" className="custom-sort-modal-reset-button" onClick={handleReset}>
              <button type="button">{I18n.t('reset', { scope })}</button>
            </ForgeButton>
            <div className="custom-sort-modal-footer-end" slot="end">
              <ForgeButton type="outlined" onClick={hideModal}>
                <button type="button">{I18n.t('cancel', { scope })}</button>
              </ForgeButton>
              <ForgeButton type="unelevated" onClick={handleApply}>
                <button type="button">{I18n.t('apply', { scope })}</button>
              </ForgeButton>
            </div>
          </ForgeToolbar>
        </ForgeScaffold>
      </div>
    </ForgeDialog>
  );
};

export default CustomSortModal;
