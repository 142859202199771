import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import MetadataProvider from '../../dataProviders/MetadataProvider';
import { BaseVisualization } from './types';
import { SourceDataOptions, SourceDataOptionsProps } from './SourceDataOptions';
import {
  isMapVisualization,
  isTableVisualization,
  newVizCardLayoutEnabled
} from 'common/visualizations/helpers/VifSelectors';

import { FeatureFlags } from 'common/feature_flags';

/**
 * Renders InfoContainer
 * - Show source link
 */

const shouldRenderExportDataOptions = (viz: BaseVisualization) =>
  !viz.isMeasure() &&
  viz.isInStory() &&
  ((isTableVisualization(viz.getVif()) &&
    !viz.isSummaryTableVisualization() &&
    FeatureFlags.value('export_data_for_tables_in_stories')) ||
    (!isTableVisualization(viz.getVif()) && FeatureFlags.value('enable_export_data_for_viz_in_stories')));

export const template = (self: BaseVisualization, vizUid?: string): JQuery => {
  const viewSourceDataLink = $('<div>', {
    id: vizUid,
    class: 'socrata-visualization-view-source-data'
  }).append(
    $('<a>', { href: '', target: '_blank' }).append([
      $('<span>').append(I18n.t('shared.visualizations.charts.common.view_source_data')),
      $('<span>', { class: 'socrata-visualization-view-source-data-icon' })
    ])
  );

  const infoTemplate = $('<div>', { class: 'socrata-visualization-info' });

  if (newVizCardLayoutEnabled(self.getVif())) {
    infoTemplate.append($('<div>', { class: 'socrata-visualization-view-source-data' }));
  } else {
    infoTemplate.append(_.compact([viewSourceDataLink]));
  }
  return infoTemplate;
};

export const renderReact = (viz: BaseVisualization, additionalProps?: Partial<SourceDataOptionsProps>) => {
  if (shouldRenderExportDataOptions(viz) || newVizCardLayoutEnabled(viz.getVif())) {
    const viewSourceContainer = viz.$element.find('.socrata-visualization-view-source-data').last();
    const showExportDataOption = shouldRenderExportDataOptions(viz);
    const showViewSourceDataLink = _.get(viz.getVif(), 'configuration.viewSourceDataLink', true);

    /**
     * EN-51751: This is needed for a weird edge case where the source data is hidden but you can still export the underlying data.
     * We rely on these classes on the viz container to pass in the right height. Without the classes, the heights would render
     * incorrectly. This can be removed once we do the viz forgification refactor
     */
    if (shouldRenderExportDataOptions(viz)) {
      const container = viz.$container;
      container.addClass('socrata-visualization-export-source-data');
    }

    if (viewSourceContainer.length) {
      ReactDOM.render(
        <SourceDataOptions
          vif={viz.getVif()}
          viz={viz}
          seriesIndex={viz.getSeriesIndex()}
          {...additionalProps}
          showExportDataOption={showExportDataOption}
          showViewSourceDataLink={
            FeatureFlags.value('enable_view_source_data_stories') && showViewSourceDataLink
          }
          vizUid={viz.$container?.attr('id')}
        />,
        viewSourceContainer[0]
      );
    }
    return;
  }

  const iconContainer = viz.$element.find('.socrata-visualization-view-source-data-icon');
  if (iconContainer.length > 0) {
    ReactDOM.render(<SocrataIcon name={IconName.External} aria-hidden={true} />, iconContainer[0]);
  }
};

export const onUpdateVif = (self: BaseVisualization) => {
  const shouldRenderViewSourceDataLink = _.get(self.getVif(), 'configuration.viewSourceDataLink', true);

  // TODO: EN-48481 the first else if is a stopgap fix for EN-48386
  // all table viz in stories need info container to have a functioning bottom dragger
  if (shouldRenderViewSourceDataLink) {
    showViewSourceDataLink(self);
  } else if (shouldRenderExportDataOptions(self)) {
    showInfoContainerOnly(self);
  } else {
    hideViewSourceDataLink(self);
  }
};

export const hideInfo = (self: BaseVisualization) => {
  const safeToHide = !self.panningNoticeVisible && !self.includeDataLinkHeight && !self.viewTabsVisible;

  if (safeToHide) {
    self.$container.removeClass('socrata-visualization-info forge-style-socrata-visualization-info');
  }
};

export const showInfo = (self: BaseVisualization) => {
  const safeToShow = self.panningNoticeVisible || self.includeDataLinkHeight || self.viewTabsVisible;

  self.$container.toggleClass('socrata-visualization-info', safeToShow);

  if (safeToShow && newVizCardLayoutEnabled(self.getVif())) {
    self.$container.addClass('forge-style-socrata-visualization-info');
  }
};

export const showViewSourceDataLink = async (self: BaseVisualization) => {
  const viewSourceDataOverride = _.get(self.getVif(), 'origin.url');
  const viewSourceDataTitle = _.get(self.getVif(), 'origin.title');
  const seriesIndex = self.getSeriesIndex();
  const renderLink = (href: string, title: string) => {
    if (self.isEmbedded()) {
      href += '?referrer=embed';
    }

    const label = I18n.t('shared.visualizations.charts.common.view_source_data_label', {
      title: title || ''
    });

    const container = self.$container;
    container.addClass('socrata-visualization-view-source-data');

    if (shouldRenderExportDataOptions(self) || newVizCardLayoutEnabled(self.getVif())) {
      renderReact(self, {
        viewSourceLinkLabel: I18n.t('shared.visualizations.charts.common.more_actions'),
        viewSourceLinkHref: href,
        seriesIndex
      });
    } else {
      container
        .find('.socrata-visualization-view-source-data a')
        .attr('aria-label', label)
        .attr('href', href);
    }

    if (shouldRenderExportDataOptions(self)) {
      container.addClass('socrata-visualization-export-source-data');
    }

    // Add the info class immediately so that visualizations can accurately
    // measure how much space they have to fill, but only add the
    // view-source-data class to show the link once the optional metadata
    // request has returned, if it is made.
    self.includeDataLinkHeight = !newVizCardLayoutEnabled(self.getVif());
    showInfo(self);
    triggerMapResizeIfNewGLMaps(self);
  };

  // Reserve space before waiting for async queries below
  // So visualizations don't measure render area prematurely
  // We had race condition between attribution domain and table page size
  showInfo(self);
  if (!_.isEmpty(viewSourceDataOverride)) {
    renderLink(viewSourceDataOverride, viewSourceDataTitle);
  } else if (_.get(self.getVif(), `series[${seriesIndex}].dataSource.type`) === 'socrata.soql') {
    const domain = _.get(self.getVif(), `series[${seriesIndex}].dataSource.domain`);
    const datasetUid = _.get(self.getVif(), `series[${seriesIndex}].dataSource.datasetUid`);
    const metadataProvider = new MetadataProvider(
      {
        datasetUid,
        ...(domain && { domain })
      },
      true
    );
    const datasetMetadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
    const attributionDomain = await metadataProvider.getAttributionDomain(datasetMetadataAndFederationStatus);

    if (attributionDomain) {
      const title = datasetMetadataAndFederationStatus.metadata.name;
      renderLink(`https://${attributionDomain}/d/${datasetUid}`, title);
    } else {
      hideInfo(self);
    }
  }
};

export const hideViewSourceDataLink = (self: BaseVisualization) => {
  if (_.get(self.getVif(), 'series[0].dataSource.type') === 'socrata.soql') {
    self.$container.removeClass('socrata-visualization-view-source-data');
    renderSourceDataOptions(self);
    self.includeDataLinkHeight = false;
    // with summary table, we still need to show info container to work properly!
    showInfo(self);
    triggerMapResizeIfNewGLMaps(self);
  }
};

const showInfoContainerOnly = (self: BaseVisualization) => {
  if (_.get(self.getVif(), 'series[0].dataSource.type') === 'socrata.soql') {
    renderSourceDataOptions(self);
    self.includeDataLinkHeight = true;
    showInfo(self);
  }
};

const renderSourceDataOptions = (self: BaseVisualization) => {
  if (newVizCardLayoutEnabled(self.getVif())) {
    renderReact(self);
  }
};

const triggerMapResizeIfNewGLMaps = (self: BaseVisualization) => {
  if (isMapVisualization(self.getVif())) {
    // resize the map canvas to fit to it's container height
    self.$container.trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }
};
