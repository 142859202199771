import ProcessDiscoveryViewButton from 'common/visualizations/views/agGridReact/ProcessDiscoveryButton';
import { View } from 'common/types/view';
import { ICellRendererParams } from '@ag-grid-community/core';
import I18n from 'common/i18n';

const scope = 'shared.visualizations.panes.presentation.fields.actions';
export function processDiscoveryColumnDef(metadata: View) {
  return {
    headerName: I18n.t('title', { scope }),
    colId: 'action',
    sortable: false,
    autoHeight: true,
    cellRenderer: (rowParams: ICellRendererParams) => {
      if (rowParams.node.group) {
        return null;
      }
      return ProcessDiscoveryViewButton({ rowParams, metadata });
    }
  };
}
