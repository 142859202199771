import {
  ApprovalState,
  Outcome,
  SubmissionObject,
  WorkflowTargetAudience
} from 'common/core/approvals_enums';

import { Vif } from 'common/visualizations/vif';
import { SoQLType } from './soql';
import { AbridgedUser, GenericUser } from './users';
import { ViewColumn } from './viewColumn';
import { ClientContextVariable } from './clientContextVariable';
import { Revision } from './revision';

type ViewGrantFlags = 'public';

export enum ViewGrantType {
  /**
   * A user who can see both the published _and_ the draft version of an asset.
   *
   * Note: When paired with the `public` flag, this means that the asset is "publicly viewable"
   * (in which case it only applies to the published version!)
   *
   * This only exists when the `strict_permissions` feature flag is false.
   */
  Viewer = 'viewer',

  /**
   * A user who can do _some_ things on an asset, but can't do things like manage collaborators.
   *
   * This only exists when the `strict_permissions` feature flag is false.
   */
  Contributor = 'contributor',

  /**
   * A "co-owner" of an asset. Can do anything but transfer ownership the asset.
   *
   * This grant type always exists no matter the state of `strict_permissions`.
   * However!
   * When `strict_permissions` is true, this is referred to as "Collaborators" in the UI.
   * When `strict_permissions` is false, this is referred to as "Co-owners" in the UI.
   */
  Owner = 'owner',

  /**
   * A special type of viewer that cannot see the draft version of an asset.
   *
   * This only exists when the `strict_permissions` feature flag is true.
   */
  PublishedViewer = 'published_viewer'
}

/** Grants are used to give "buckets" of permissions on a view to a user or a team (or "the public"). */
interface ViewGrant {
  inherited: boolean;
  type: ViewGrantType;
  userId?: string;

  /**
   * If this has the "public" flag, it means that this grant is accessible to _all_ users,
   * even users who are not logged in.
   *
   * The main usage of this is the "public viewer" grant which is how an asset is actually made public.
   */
  flags?: ViewGrantFlags[];
}

export interface CustomField {
  private: boolean;
  dataTypeName: SoQLType;
  renderTypeName: SoQLType;
  tableColumnId: number;
  name: string;
  required: boolean;
  options?: string[];
}

export interface CustomFieldset {
  name: string;
  fields: CustomField[];
}

// DSLP only, frontend/lib/dataset_landing_page.rb adds in an additional attribute
export interface DatasetLandingPageCustomFieldset extends CustomFieldset {
  existing_fields: Array<{ [fieldName: string]: string }>;
}

export interface Approval {
  message?: string;
  state: ApprovalState;
  submissionDetails?: {
    permissionType: ViewRight;
  };
  submissionId?: number;
  submissionObject?: SubmissionObject;
  submissionOutcome?: Outcome;
  submissionOutcomeApplication?: {
    endedAt: number;
    failureCount: number;
    startedAt: number;
    status: string;
  };
  submittedAt?: number;
  submitter?: AbridgedUser;
  targetAudience: WorkflowTargetAudience;
  workflowId: number;
}

/**
 * Used to describe the visibility of an asset when looking at its `permissions`
 */
export enum AudienceScope {
  /** The asset can be seen by anybody who can see the site */
  Public = 'public',

  /** The asset can only be seen by specific people it is shared with */
  Private = 'private',

  /** The asset can be seen by anybody with the `can_view_internal_data` domain right */
  Site = 'site'
}

/**
 * In general, these are what we actually use for permissions checks.
 * The actual actions that are tied to each of these is very nebulous and up for interpretation,
 * so be careful how you use them!
 *
 * A user gets (almost) all of these if they are the "true owner" of an asset.
 * A user with the `edit_others_datasets` domain right gets (almost) all of these on every asset on the domain.
 * Otherwise, these are given to users via explicit grants to either the user specifically, or to a team the user is on.
 *
 * @see ViewGrantType
 */
export enum ViewRight {
  /**
   * Lets the user change existing rows in the asset.
   * If the asset is not tabular, we often use this to check if the user can change the asset in _some way_.
   *
   * Grants that get `write`:
   * - contributor
   * - owner
   */
  Write = 'write',

  /**
   * Lets the user add new rows to the asset.
   *
   * Grants that get `add`:
   * - contributor
   * - owner
   */
  Add = 'add',

  /**
   * !!!WARNING!!!: This is NOT to be checked for deleting an actual asset!!! Check `delete_view` instead!!!
   *
   * Lets the user delete ROWS from the asset.
   *
   * Again,
   * !!!WARNING!!!: This is NOT to be checked for deleting an actual asset!!! Check `delete_view` instead!!!
   *
   * Grants that get `delete`:
   * - contributor
   * - owner
   */
  Delete = 'delete',

  // Note that if a user has any one of `remove_column`, `add_column`, or `update_column` they will have all three of them.
  // You can think of having any of these as being "manage schema".
  /**
   * Lets the user remove columns from a dataset.
   *
   * Grants that get `remove_column`:
   * - `owner`
   */
  RemoveColumn = 'remove_column',

  /**
   * Lets the user add new columns to a dataset.
   *
   * Grants that get `add_column`:
   * - `owner`
   */
  AddColumn = 'add_column',

  /**
   * Lets the user change columns on a dataset.
   *
   * Grants that get `update_column`:
   * - `owner`
   */
  UpdateColumn = 'update_column',

  /**
   * `update_view` is _really_ just "manage metadata". If you have this, you can rename a view and change its other metadata fields.
   *
   * Grants that get update_view:
   * - `contributor`
   * - `owner`
   */
  UpdateView = 'update_view',

  /**
   * Lets a user delete the underlying view.
   *
   * Grants that get `delete_view`:
   * - `owner`
   */
  DeleteView = 'delete_view',

  /**
   * Lets a user lock the underlying view.
   */
  LockView = 'can_lock_asset',

  /**
   * `grant` allows a user to add grants to the asset. This lets them share the asset with other users and make it public or internal.
   *
   * Grants that get `grant`:
   * - `owner`
   */
  Grant = 'grant',

  /**
   * This means that the user can see the asset.
   *
   * Grants that get `read`:
   * - `viewer`
   * - `contributor`
   * - `owner`
   *
   * Note: The `published_viewer` grant will get this, but only on the published version of an asset.
   *
   * Note: Users with the `view_others_datasets` domain right will get this on every asset on the domain.
   */
  Read = 'read',

  /**
   * Lets the user transfer ownership of an asset.
   *
   * Users with the `chown_datasets` domain right will have this on every asset.
   *
   * If `strict_permissions` is on, the "true owner" of an asset will also have this.
   */
  TransferOwnership = 'transfer_ownership',

  /**
   * Lets the user manage the provenance of an asset.
   *
   * Users with the `manage_provenance` domain right will have this on every asset.
   */
  ManageProvenance = 'manage_provenance'
}

/** Paired with the `displayType` of a view, defines what kind of view it is */
export enum ViewType {
  Blobby = 'blobby',
  Tabular = 'tabular',
  Href = 'href',
  Geo = 'geo',
  Story = 'story',
  Measure = 'measure',
  ParameterizedView = 'parameterized_view'
}

/** Paired with the `viewType` of a view, defines what kind of view it is */
export enum DisplayType {
  AssetInventory = 'assetinventory',
  Blob = 'blob',
  Calendar = 'calendar',
  Chart = 'chart',
  Draft = 'draft',
  FatRow = 'fatrow',
  Federated = 'federated',
  Form = 'form',
  GeoRows = 'geoRows',
  Href = 'href',
  Map = 'map',
  Measure = 'measure',
  MetadataTable = 'metadata_table',
  Page = 'page',
  ParameterizedView = 'parameterized_view',
  Pulse = 'pulse',
  Story = 'story',
  Table = 'table',
  Visualization = 'visualization', // Legacy support for now.
  VisualizationCanvasTable = 'visualization_canvas_table',
  VisualizationCanvasChart = 'visualization_canvas_chart',
  VisualizationCanvasCalendar = 'visualization_canvas_calendar',
  VisualizationCanvasMap = 'visualization_canvas_map'
}

export enum AssetType {
  Calendar = 'calendar',
  Chart = 'chart',
  Dataset = 'dataset',
  FederatedHref = 'federated_href',
  InvalidDataType = 'invalid_datatype',
  File = 'file',
  Filter = 'filter',
  Form = 'form',
  Href = 'href',
  Map = 'map',
  Measure = 'measure',
  Pulse = 'pulse',
  Story = 'story',
  SystemDataset = 'system_dataset',
  Visualization = 'visualization'
}

export enum ViewFlag {
  /** The view is internal to public federated. Does not check user's right to know that the view is federated. */
  InternalToPublicFederatedView = 'internalToPublicFederatedView',

  /** This means that at least one of the outgoing federation relationships that would federate this view is of type internal to public */
  InternalToPublicFederatedSourceView = 'internalToPublicFederatedSourceView',

  /** The view is catalog federated. (You need to send the X-Socrata-Federation header to access a catalog federated view through a target site). */
  CatalogFederatedView = 'catalogFederatedView',

  /**
   * Determines if the default lens is on another domain, not whether the current view is federated.
   *
   * This does not do what you expect!
   * You probably want internalToPublicFederatedView or catalogFederatedView.
   */
  Federated = 'federated',

  /** This will come back if this view is a SoQL view that has a SoQL query string */
  SoqlBasedView = 'soqlBasedView',

  /** A default view is a base view and has no parent, and can have children */
  Default = 'default'
}

/**
 * Names for different types of access level
 */
export enum AccessLevelName {
  /**
   * If paired with an access level version of "all", this user can see draft and published versions of the asset.
   * If paired with an access level version of "published", this user can _only_ see the published version of the asset
   */
  Viewer = 'viewer',

  /**
   * This grant is poorly understood in terms of what it can do.
   * Generally, this has everything that "viewer" does along with some edit capabilities.
   */
  Contributor = 'contributor',

  /**
   * A "co-owner" of the asset.
   * NOTE: When `strict_permissions` is turned on, this is referred to as "Collaborator" in the UI
   */
  Owner = 'owner',

  /** The asset's "true" owner. */
  CurrentOwner = 'current_owner'
}

/** Versions of an asset that an access level applies to */
export enum AccessLevelVersion {
  /** Applies to all versions of the asset */
  All = 'all',

  /** Only applied to the published version of the asset */
  Published = 'published',

  /**
   * Only applies to drafts of the asset
   * NOTE: This is currently unused!
   */
  Draft = 'draft'
}

/**
 * Defines an "access level" that a user can have in the `permission` blob of a view.
 *
 * Currently valid access levels:
 * Always available:
 * { name: `owner`, version: `all` }
 *  When `strict_permissions` is off, this is referred to as "Co-owner" in the UI
 *  When `strict_permissions` is on this is referred to as "Collaborator" in the UI
 *
 * `strict_permissions` OFF:
 * { name: `viewer`, version: `all` }
 * { name: `contributor`, version: `all` }
 *  Users can select these from a dropdown in the access manager
 *
 * `strict_permissions` ON:
 * { name: `viewer`, version: `published` }
 *  This is referred to as "Viewers" in the UI
 */
export interface AccessLevel {
  /** The type of access level */
  name: AccessLevelName;

  /** Which version of the asset this access level applies to. */
  version: AccessLevelVersion;
}

/** A user who has permissions on a view */
export interface ViewUser {
  /**
   * UID (4x4) of the user
   * NOTE: If the user is deleted, this will be undefined!
   */
  id?: string;

  /** Name to show for the user. */
  displayName: string;

  /** This will only come back for interactive users and the user has the appropriate permissions,
   *  `manage_users`. Teams currently do not have emails.
   */
  email?: string;

  /** Type of user */
  type: 'interactive' | 'team';

  /** While this is an array, each user can only have one access level at a time on an asset. */
  accessLevels: AccessLevel[];

  /**
   * If the view user is disabled, they come back with a 'disabled' flag.
   * 'disabled' is currently the only flag that comes back here.
   */
  flags?: 'disabled'[];
}

export enum PublicationStage {
  Copying = 'copying',
  Unpublished = 'unpublished',
  Snapshotted = 'snapshotted',
  Published = 'published'
}

/**
 * Comes in the `copying.copyStatus` portion of the response from
 * /api/views/{uid}.json?method=operationStatuses
 */
export enum CopyStatus {
  Queued = 'queued',
  Processing = 'processing',
  Finished = 'finished',
  Failed = 'failed'
}

/**
 * Permissions for the view...
 * Note that there is some overlap here with "grants".
 * Generally, looking at permissions is preferred but not all users can see them (only users who have the `grant` permission on the view)
 */
export interface ViewPermissions {
  scope: AudienceScope;
  accessLevels?: AccessLevel[];

  /** If this is not the default view, then this is the parent of the view. */
  parent?: {
    scope: AudienceScope;
    viewId: string;
  };

  users?: ViewUser[];
}

export enum Provenance {
  Official = 'official',
  Community = 'community',
  Unknown = 'unknown'
}

export interface DisplayFormat {
  visualizationCanvasMetadata?: {
    vifs: Vif[];
  };
}

export interface UdfParameter {
  name: string;
  description?: string;
  type: SoQLType;
}

export interface UdfDefinition {
  soql: string;
  parameters: UdfParameter[];
}

/**
 * These attributes may only exit on a view object in specific contexts.
 * For example, many of these are available in DSLP, but not elsewhere.
 * Some may be never be on the view object, and returned in other contexts.
 * Just review and consider modifying this interface if you can trace down how they should be defined.
 */
interface ContextUnknownViewAttributes {
  computedColumns?: ViewColumn[];
}

/**
 * These are both contextual in some way. Not sure how to reflect that in the TS.
 * TODO: Confirm how these are returned and fix the TS.
 */
interface DomainViewAttributes {
  /**
   * Returned if the request was made with the `X-Socrata-Federation: Honey Badger` header,
   * regardless of if the view is federated. Use this for data access actions on federated views.
   */
  domainCName?: string;
  /**
   * If the view is federated (and the user can see the source domain),
   * what domain it came from. This should only be used to link to management/editing
   * actions. Use `domainCName` field for data access actions.
   * @note: (Domain Aliases) It will always give you the primary cname
   */
  sourceDomainCName?: string;
}

export interface View extends ContextUnknownViewAttributes, DomainViewAttributes {
  id: string;
  name: string;
  resourceName?: string;
  assetType: AssetType;
  createdAt: number;
  category?: string;
  description?: string;
  queryString?: string;
  udfDefinition?: UdfDefinition;
  displayType: DisplayType;
  indexUpdatedAt?: number;
  attribution?: string;
  attributionLink?: string;

  displayFormat?: DisplayFormat;

  /** If the view will be federated and the user is allowed to see them, list of domain cnames it will be federated to. */
  federatedTo?: string[];

  hideFromCatalog: boolean;
  message?: string;
  newBackend: boolean;
  provenance: Provenance;
  publicationAppendEnabled: boolean;
  publicationDate: number;
  publicationGroup: number;
  publicationStage: PublicationStage;
  rowIdentifierColumnId?: number;
  rowsUpdatedAt: number;
  rowsUpdatedBy?: string;

  searchString?: string;

  tableId: number;
  viewCount: number;
  viewLastModified: number;
  viewType: ViewType;
  approvals: Approval[];
  columns: ViewColumn[];
  metadata: { [property: string]: any };
  privateMetadata?: any;
  locked: boolean;

  /**
   * The user who owns this view.
   * This can be either an InteractiveUser or a team.
   * In general, if this is a team, all members of that team are considered owners.
   *
   * NOTE: If the owner is a deleted user, this will be undefined. Be careful!
   */
  owner?: GenericUser;

  totalTimesRated?: number;
  averageRating?: number;
  downloadCount?: number;
  tableAuthor?: any;
  hideFromDataJson?: boolean;
  numberOfComments?: number;
  // when the user is not rolled they don't have permission to see permissions, so will be undefined.
  permissions?: ViewPermissions;
  query: any;
  rights: ViewRight[];
  flags: ViewFlag[];
  license?: {
    name: string;
    termsLink?: string;
  };
  licenseId?: string;

  clientContext?: {
    clientContextVariables?: ClientContextVariable[];
    // TODO: this is an empty object when it's empty. What about when it's not empty?
    inheritedVariables?: unknown;
  };

  grants: ViewGrant[];
  tags?: string[];
  modifyingViewUid?: string;
  publishedViewUid?: string;
  plotlyUrl?: string;
}

export interface ViewAttachment {
  filename: string;
  assetId: string;
  name: string;
}

// DSLP makes some changes to the view's attachments at frontend/lib/dataset_landing_page.rb#490
// before passing it down to the JS code.
export interface DatasetLandingPageAttachment {
  name: string;
  href: string;
  link: string;
}

// DLSP only
export interface AccessPoint {
  title: string;
  description: string;
  urls: Array<string>;
}

// frontend/lib/dataset_landing_page.rb attaches additional fields to the view object.
// Only use this type when you know that the view has been routed through there!
export interface DatasetLandingPageEnhancedView {
  coreView: View;
  viewCount: number;
  tags: string[];
  facebookShareUrl: string;
  twitterShareUrl: string;
  emailShareUrl: string;
  rowLabelMultiple: string;
  rowLabel: string;
  resourceUrl: string;
  provenance: Provenance;
  plotlyUrl?: string;
  permissions: ViewPermissions;
  ownerName?: GenericUser;
  odataUrlV4?: string;
  odataUrl?: string;
  openRevisions: Array<Revision>;
  namedResourceUrl?: string;
  name: string;
  metadata: {
    rdfSubject?: string;
    rdfClass?: string;
    attachments?: Array<ViewAttachment>;
    custom_fields?: CustomFieldset[];
    rowLabel?: string;
    availableDisplayTypes?: Array<DisplayType>;
    [key: string]: any;
  };
  licenseName?: string;
  licenseLogo?: string;
  licenseLink?: string;
  lastUpdatedAt?: string;
  isUnpublished: boolean;
  isTabular: boolean;
  isPrivate: boolean;
  isHref: boolean;
  isBlobby: boolean;
  id: string;
  hasViewerGrant: boolean;
  gridUrl: string;
  geoJsonResourceUrl?: string;
  exportFormats: Array<string>;
  exploreUrl: string;
  editUrl: string;
  editMetadataUrl?: string;
  disableContactDatasetOwner: boolean;
  description?: string;
  dsmapiScheduledUpdates: {
    [key: string]: any;
  };
  dsmapiAutomationGuidance: {
    [key: string]: any;
  };
  customMetadataFieldsets: Array<DatasetLandingPageCustomFieldset>;
  csvResourceUrl: string;
  createdAt?: string;
  commentUrl?: string;
  columns: Array<ViewColumn>;
  parentView?: View;
  collectionParentView?: View;
  category?: string;
  cartoUrl?: string;
  canPublish: boolean;
  bootstrapUrl?: string;
  blobType?: string;
  blobMimeType?: string;
  blobId?: string;
  blobFilename?: string;
  attributionLink?: string;
  attribution?: string;
  attachments?: Array<DatasetLandingPageAttachment>;
  apiFoundryUrl: string;
  allAccessPoints: Array<AccessPoint>;
  subscribed?: boolean;
  subscriptionId?: string;
  analysisId?: string;
  rowCount?: number;
}
