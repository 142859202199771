// Vendor Imports
import bindAll from 'lodash/bindAll';
import includes from 'lodash/includes';
import merge from 'lodash/merge';
import React, { Component, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ForgeCheckbox, ForgePopup } from '@tylertech/forge-react';
import { IPopupComponent } from '@tylertech/forge';

// Project Imports
import SocrataIcon, { IconName } from '../SocrataIcon';
import ValueEditor from './ParameterValueEditor';
import { getParameterHumanText, parameterHasSpecificValues } from './lib/Filters';
import I18n from 'common/i18n';

import { isInsideFlannels } from './lib/helpers';
import { Key } from 'common/types/keyboard/key';

import { SoQLType } from 'common/types/soql';
import { FilterBarPendoIds } from './types';
import { DataSourceCCVs, ParameterConfiguration } from 'common/types/reportFilters';
import ParameterDropdown from './ParameterDropdown';
import FeatureFlags from 'common/feature_flags';
import { findClientContextVariableDefault } from 'common/components/FilterBar/lib/allFilters';

export interface ParameterItemProps {
  clientContextVariables?: DataSourceCCVs;
  disabled?: boolean;
  editMode: boolean;
  onParameterUpdate: (parameter: ParameterConfiguration) => void;
  parameter: ParameterConfiguration;
  pendoIds?: FilterBarPendoIds;
}

interface ParameterItemState {
  isControlOpen: boolean;
}

export class ParameterItem extends Component<ParameterItemProps, ParameterItemState> {
  private parameterControl = React.createRef<HTMLSpanElement>();
  private parameterControlToggle = React.createRef<HTMLDivElement>();
  private controlContainer = React.createRef<HTMLDivElement>();
  private parameterControlPopupRef = React.createRef<IPopupComponent & HTMLElement>();

  bodyClickHandler: (e: MouseEvent) => void;
  bodyEscapeHandler: (e: KeyboardEvent) => void;

  constructor(props: ParameterItemProps) {
    super(props);

    this.state = {
      isControlOpen: false
    };

    bindAll(this, [
      'toggleControl',
      'onKeyDownControl',
      'onKeyUpControl',
      'onReset',
      'onUpdate',
      'renderParameterControl',
      'renderParameterControlToggle'
    ]);
  }

  componentDidMount() {
    this.bodyClickHandler = (event) => {
      // Avoid closing flannels if the click is inside any of these refs.
      const flannelElements = [this.parameterControl.current, this.parameterControlToggle.current];

      const clickInsideFlannels = isInsideFlannels(event, flannelElements);

      // If none of the flannelElements contain event.target, close all the flannels.
      if (!clickInsideFlannels) {
        this.closeAll();
      }
    };

    this.bodyEscapeHandler = (event) => {
      const { isControlOpen } = this.state;
      if (event.key === Key.Escape) {
        if (isControlOpen && this.parameterControlToggle.current) {
          this.closeAll();
          this.parameterControlToggle.current.focus();
        }
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
    document.body.addEventListener('keyup', this.bodyEscapeHandler);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClickHandler);
    document.body.removeEventListener('keyup', this.bodyEscapeHandler);
  }

  toggleControl(): void {
    const { clientContextVariables, parameter } = this.props;

    this.setState({
      isControlOpen: !this.state.isControlOpen
    });

    // When opening control (i.e., current isControlOpen value is false), focus on the first input element.
    // If we update this component to use ForgePopup in the future, we may need to use a solution similar to
    // FilterEditor/InputFocus.
    if (!this.state.isControlOpen) {
      const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
        'enable_parameter_dropdowns_in_stories',
        false
      );
      setTimeout(() => {
        if (
          enableParameterDropdownsInStories &&
          parameterHasSpecificValues(parameter, clientContextVariables)
        ) {
          const listItems = this.parameterControlPopupRef.current?.querySelectorAll('forge-list-item') as any;
          if (listItems && listItems[0]) {
            listItems[0].focus();
          }
        } else {
          this.parameterControl.current?.querySelector('input')?.focus();
        }
      });
    }
  }

  onKeyDownControl(event: React.KeyboardEvent): void {
    if (event.key === Key.Space) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onKeyUpControl(event: React.KeyboardEvent): void {
    if (includes([Key.Enter, Key.Space], event.key)) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleControl();
    }
  }

  onReset() {
    const { clientContextVariables, editMode, parameter } = this.props;

    // If in viewmode, reset to the override in the published story. If in editmode, reset to the
    // default in the parameter's client context variable.
    const resetValue = findClientContextVariableDefault(clientContextVariables, parameter, editMode);
    const newParameter: ParameterConfiguration = merge({}, parameter, {
      overrideValue: resetValue
    });

    this.setState((prev) => ({ ...prev, isLoading: true }));
    this.props.onParameterUpdate(newParameter);
    this.setState((prev) => ({ ...prev, isLoading: false }));
    this.closeAll();
    if (this.parameterControlToggle.current) {
      this.parameterControlToggle.current.focus();
    }
  }

  onUpdate(parameterOverrideValue: string) {
    const { parameter } = this.props;

    const newParameter: ParameterConfiguration = merge({}, parameter, {
      overrideValue: parameterOverrideValue
    });

    this.setState((prev) => ({ ...prev, isLoading: true }));
    this.props.onParameterUpdate(newParameter);
    this.setState((prev) => ({ ...prev, isLoading: false }));
    this.closeAll();
    if (this.parameterControlToggle.current) {
      this.parameterControlToggle.current.focus();
    }
  }

  renderDisplayName(): string {
    const { parameter } = this.props;

    if (parameter.dataType === SoQLType.SoQLBooleanT) return '';
    else return parameter.displayName;
  }

  renderParameterControlToggle(): JSX.Element {
    const { parameter, disabled, editMode, clientContextVariables } = this.props;
    const { isControlOpen } = this.state;

    if (parameter.dataType === SoQLType.SoQLBooleanT) {
      return (
        <div className={editMode ? 'filter-control-toggle boolean-parameter' : ''}>
          <ForgeCheckbox dense>
            <input
              type="checkbox"
              id={`parameter-${parameter.paramIds[0].name}-checkbox`}
              checked={parameter.overrideValue === 'true' ? true : false}
              onChange={(e: any) => this.onUpdate(e.target.checked.toString())}
            />
            <label
              htmlFor={`parameter-${parameter.paramIds[0].name + parameter.paramIds[0].datasetUid}-checkbox`}
            >
              {getParameterHumanText(parameter, clientContextVariables)}
            </label>
          </ForgeCheckbox>
        </div>
      );
    } else {
      const toggleProps: HTMLAttributes<HTMLDivElement> = {
        className: classNames('filter-control-toggle btn-default', {
          active: isControlOpen,
          disabled
        }),
        'aria-label': `${I18n.t('shared.components.filter_bar.parameter')} ${parameter.displayName}`,
        tabIndex: 0
      };

      if (!disabled) {
        toggleProps.role = 'button';
        toggleProps.onClick = this.toggleControl;
        toggleProps.onKeyDown = this.onKeyDownControl;
        toggleProps.onKeyUp = this.onKeyUpControl;
      }

      return (
        <div {...toggleProps} ref={this.parameterControlToggle}>
          {getParameterHumanText(parameter, clientContextVariables)}
          <span className="arrow-down-icon">
            <SocrataIcon name={IconName.ArrowDown} aria-hidden={true} />
          </span>
        </div>
      );
    }
  }

  renderParameterControl(): JSX.Element | null {
    const { isControlOpen } = this.state;
    const { clientContextVariables, parameter } = this.props;
    const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
      'enable_parameter_dropdowns_in_stories',
      false
    );

    if (!isControlOpen) {
      return null;
    }

    const valueEditorProps = {
      parameter,
      onUpdate: this.onUpdate,
      onReset: this.onReset
    };

    const freeformParameter = (
      <span ref={this.parameterControl}>
        <ValueEditor {...valueEditorProps} />
      </span>
    );

    const parameterDropdownProps = {
      parameter,
      clientContextVariables,
      onReset: this.onReset,
      onUpdate: this.onUpdate
    };
    const dropDownParameter = <ParameterDropdown {...parameterDropdownProps} />;

    // If the parameter or any of its linked parameters has specific values, then use a dropdown
    // list with those values, otherwise use a text box.
    const useDropdown =
      enableParameterDropdownsInStories &&
      clientContextVariables &&
      parameterHasSpecificValues(parameter, clientContextVariables);

    return (
      <ForgePopup
        ref={this.parameterControlPopupRef}
        open
        options={{ placement: 'bottom-end' }}
        targetElementRef={this.parameterControlToggle}
      >
        {useDropdown ? dropDownParameter : freeformParameter}
      </ForgePopup>
    );
  }

  closeAll(): void {
    this.setState({
      isControlOpen: false
    });
  }

  render(): JSX.Element {
    const { pendoIds } = this.props;
    return (
      <div className="filter-bar-filter parameter-item">
        <label className="filter-control-label">{this.renderDisplayName()}</label>
        <div
          className="filter-control-container"
          ref={this.controlContainer}
          id={pendoIds?.filterParameterControlContainer}
          data-testid="filter-bar-parameter-control-container"
        >
          {this.renderParameterControlToggle()}
          {this.renderParameterControl()}
        </div>
      </div>
    );
  }
}

export default ParameterItem;
