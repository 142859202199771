import { ForgeSelect, ForgeOption } from '@tylertech/forge-react';
import React, { FunctionComponent } from 'react';
import { ResourceType } from './ExportModalTypes';
import { fetchTranslation } from 'common/locale';

export type DownloadFileTabProps = {
  downloadResources: ResourceType[];
  handleExportChange: (format: string) => void;
  selectedDownloadResource?: ResourceType;
  filteringRadioButtons: JSX.Element | null;
};

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');

const DownloadFileTab: FunctionComponent<DownloadFileTabProps> = ({
  downloadResources,
  handleExportChange,
  selectedDownloadResource,
  filteringRadioButtons
}) => {
  const props = {
    onChange: (event: CustomEvent) => {
      handleExportChange(event.detail); // here is where we are setting the selected resource
    },
    label: t('export_format'),
    className: 'forge-select export-formats',
    selectedIndex: selectedDownloadResource
      ? downloadResources.findIndex((opt: ResourceType) => opt.value === selectedDownloadResource.value)
      : 0
  };

  return (
    <>
      <ForgeSelect data-testid="export-type-select" {...props}>
        {downloadResources.map((downloadResource: ResourceType, index) => {
          return (
            <ForgeOption
              value={downloadResource.value}
              key={`export-option-${index}`}
              secondaryLabel={downloadResource.value === 'xlsx' ? t('xlsx_secondary_label') : ''}
              data-testid={`export-type-select-option-${index}`}
            >
              {downloadResource.label}
            </ForgeOption>
          );
        })}
      </ForgeSelect>
      {filteringRadioButtons}
    </>
  );
};

export default DownloadFileTab;
