import { mapValues } from 'lodash';

import { Row } from './type';
import { TableColumn } from '../types';

export const parseRows = (rows: Row[], tableColumns: TableColumn[]): Row[] => {
  const numericSortColumns = tableColumns
    .filter((tableColumn) => tableColumn.type === 'number')
    .map((tableColumn) => tableColumn.field!);

  if (numericSortColumns.length === 0) {
    return rows;
  }

  return rows.map((row) => {
    return mapValues(row, (value: any, columnName: string) => {
      if (numericSortColumns.includes(columnName)) {
        return parseFloat(value);
      }
      return value;
    });
  });
};
