import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';
import DrilldownPane from 'common/components/DrilldownPane';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import TimeDataManager from '../../dataProviders/TimeDataManager';
import {
  getCurrentDrilldownColumnName,
  getDatasetUid,
  getDrilldowns,
  getFilters,
  getNewFilters,
  shouldRenderDrillDown,
  shouldDisableDrilldownResetButton
} from 'common/visualizations/helpers/VifSelectors';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { ViewColumn } from 'common/types/viewColumn';
import { NUMERIC_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import {
  applyFilterForColumnNameAndGetFilters as applyNewFilterForColumnNameAndGetFilters,
  getNextDrilldownDimensionColumnName as getNextDrilldownDimensionColumnNameForNewFilters
} from 'common/components/FilterBar/lib/drilldowns';

import { Vif } from 'common/visualizations/vif';
import { BaseVisualization } from './types';

/**
 * - Renders the Drilldown UI
 * - Various helpers to manage drilldown state and UI
 */

export const template = (): JQuery => $('<div>', { class: 'socrata-visualization-drilldown-container' });

export const renderDrilldownPane = (self: BaseVisualization) => {
  if (shouldRenderDrillDown(self.getVif())) {
    const emitDrilldownChange = _.get(self.getOptions(), 'drilldown.emitDrilldownChange', false);
    const handleVifUpdatesInternally = _.get(
      self.getOptions(),
      'drilldown.handleVifUpdatesInternally',
      false
    );
    const currentDrilldownDimensionColumnName = getCurrentDrilldownColumnName(self.getVif());
    self.$container.addClass('socrata-visualization-drill-down');
    const props = {
      currentDrilldownDimensionColumnName,
      datasetUid: getDatasetUid(self.getVif()),
      columns: self.getColumns(),
      drilldowns: getDimensionAndDrilldowns(self.getVif()),
      filters: getFilters(self.getVif()),
      isEditMode: emitDrilldownChange && !handleVifUpdatesInternally,
      shouldDisableResetButton: shouldDisableDrilldownResetButton(self.originalVif, self.getVif()),
      onDrilldownChange: onDrilldownChange(self),
      onDrilldownReset: onClickDrilldownReset(self),
      getDisplayNameForColumn: getDisplayNameForColumn(self.getColumns()!) // I presume this will not be null, but I don't know.
    };

    ReactDOM.render(
      React.createElement(DrilldownPane, props),
      self.$container.find('.socrata-visualization-drilldown-container')[0]
    );
  } else {
    self.$container.removeClass('socrata-visualization-drill-down');
    ReactDOM.unmountComponentAtNode(self.$container.find('.socrata-visualization-drilldown-container')[0]);
  }
};

/**
 * This is a function that returns a function so that it can be bound to the
 * BaseVisualization state and also be used as an event handler
 */
export const onDrilldownChange =
  (self: BaseVisualization) => (newFilters: SoqlFilter[], newDrilldownDimensionColumnName?: string) => {
    const isEmitDrilldownChange = _.get(self.getOptions(), 'drilldown.emitDrilldownChange', false);
    const getNewVif = () => {
      const newVif = _.cloneDeep(self.getVif());
      _.each(newVif.series, (series) => {
        _.set(series, 'dataSource.filters', newFilters);
        _.set(series, 'dataSource.dimension.currentDrilldownColumnName', newDrilldownDimensionColumnName);
      });
      return newVif;
    };

    if (isEmitDrilldownChange) {
      self.emitEvent('SOCRATA_VISUALIZATION_FILTERS_CHANGED', { filters: newFilters });
      self.emitEvent('SOCRATA_VISUALIZATION_DRILL_DOWN_CHANGED', {
        currentDrilldownDimensionColumnName: newDrilldownDimensionColumnName
      });
      self.emitEvent('SOCRATA_VISUALIZATION_VIF_UPDATED', getNewVif());
    } else {
      self.emitVifEvent(getNewVif());
    }
  };

export const onDrilldown = async (
  self: BaseVisualization,
  dimension: string,
  columnData: { columnFormats: { [columnName: string]: ViewColumn } }
) => {
  const otherLabel = I18n.t('shared.visualizations.charts.common.other_category');
  if (_.isEqual(otherLabel, dimension)) {
    return;
  }
  if (shouldRenderDrillDown(self.getVif())) {
    const filters = getFilters(self.getVif());
    const columnName = getCurrentDrilldownColumnName(self.getVif());
    const columnDetails = _.find(columnData.columnFormats, { fieldName: columnName });
    if (!columnDetails) {
      return;
    }

    const isNumericalColumn = _.includes(NUMERIC_COLUMN_TYPES, columnDetails.renderTypeName!);
    if (isNumericalColumn && _.isNil(dimension)) {
      return;
    }
    const precision = _.get(self.getVif(), 'series[0].dataSource.precision');
    let period: moment.unitOfTime.StartOf = 'day';
    /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
    if (_.includes(['calendar_date', 'date'], columnDetails.renderTypeName!) && precision !== 'none') {
      period = await TimeDataManager.getPrecisionBySeriesIndex(self.getVif(), 0);
    }

    const newFilters = applyNewFilterForColumnNameAndGetFilters({
      columnDetails,
      columnName,
      columnValue: dimension,
      filters: filters as SoqlFilter[],
      period
    });

    const nextDrilldownDimension = getNextDrilldownDimensionColumnNameForNewFilters(
      getDimensionAndDrilldowns(self.getVif()),
      columnName
    );

    onDrilldownChange(self)(newFilters, nextDrilldownDimension);
  }
};

const onClickDrilldownReset =
  (self: BaseVisualization) => (newFilters: SoqlFilter[], newDrilldownDimensionColumnName: string) => {
    const handleVifUpdatesInternally = _.get(self.getOptions(), 'drilldown.handleVifUpdatesInternally', true);

    if (handleVifUpdatesInternally) {
      newFilters = getNewFilters(self.originalVif, self.getVif());
      newDrilldownDimensionColumnName = getCurrentDrilldownColumnName(self.originalVif);
    }

    onDrilldownChange(self)(newFilters, newDrilldownDimensionColumnName);
  };

// Exported for testing
export const getDisplayNameForColumn = (columns: ViewColumn[]) => (column: string) => {
  return _.chain(columns).find({ fieldName: column }).get('name').value();
};

const getDimensionAndDrilldowns = (vif: Vif) => {
  const dimension = _.get(vif.series[0], 'dataSource.dimension.columnName');
  const drilldowns = _.map(getDrilldowns(vif), 'columnName');

  return _.compact([dimension, ...drilldowns]);
};
