import { useState, useEffect } from 'react';
import { buildQueryString } from 'common/http';
import { ContextParams } from 'common/visualizations/views/agGridReact/helpers/processDiscoveryHelper';

interface ExtraParams {
  showAllActions?: boolean;
  isBatchFetch?: boolean;
  primaryValues?: string;
}

interface FetchProcessDisconveryParams {
  primaryColumnValue: string | number;
  contextParams: ContextParams | object;
  extraParams?: ExtraParams;
}

export const fetchProcessDiscoveryData = async ({
  primaryColumnValue,
  contextParams,
  extraParams
}: FetchProcessDisconveryParams) => {
  const params = { ...contextParams, ...extraParams };
  const url = `/process_discovery/actions/${primaryColumnValue}.json?${buildQueryString(params)}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch process discovery results');
  }

  const data = await response.json();
  return data?.results;
};

export const useProcessDiscoveryResults = (
  primaryColumnValue: string | number,
  contextParams: ContextParams | object,
  extraParams?: ExtraParams
) => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchProcessDiscoveryResults = async () => {
    try {
      if (primaryColumnValue) {
        setLoading(true);

        const processDisconveryData = await fetchProcessDiscoveryData({
          primaryColumnValue,
          contextParams,
          extraParams
        });

        setResults(processDisconveryData || {});
      } else {
        setResults({});
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProcessDiscoveryResults();
  }, [primaryColumnValue]);

  return { processDiscoveryResults: results, loading, error };
};
