// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Project Imports
import I18n from 'common/i18n';
import FilterPropType from 'common/propTypes/FilterPropType';
import SocrataIcon from 'common/components/SocrataIcon';
import { getNoopFilter } from 'common/components/FilterBar/lib/Filters/BaseFilter';
import Button from 'common/components/Button';

// Constants
const scope = 'shared.visualizations.charts.drill_down';

export default class DrilldownPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHierarchyListContainer: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (e) => {
    const { showHierarchyListContainer } = this.state;
    if (!showHierarchyListContainer) return;

    const isClickWithinHierarchyListContainer = $(e.target).closest('.hierarchy-container').length > 0;
    const isClickWithinDrilldownButtonGroup = $(e.target).closest('.drill-down-btn-group').length > 0;

    if (!isClickWithinDrilldownButtonGroup || isClickWithinHierarchyListContainer) {
      this.setState({ showHierarchyListContainer: false });
    }
  };

  isFirstDrilldownDimension = () => {
    const { currentDrilldownDimensionColumnName, drilldowns } = this.props;
    const currentDrilldownDimensionIndex = _.indexOf(drilldowns, currentDrilldownDimensionColumnName);

    return currentDrilldownDimensionIndex === 0;
  };

  getPreviousDrilldownDimension = () => {
    const { currentDrilldownDimensionColumnName, drilldowns } = this.props;
    const drilldownOwnIndex = _.indexOf(drilldowns, currentDrilldownDimensionColumnName);

    return _.get(drilldowns, [drilldownOwnIndex - 1], currentDrilldownDimensionColumnName);
  };

  getDrillDownFilters = () => {
    const { drilldowns, filters } = this.props;

    const drillDownFilters = _.filter(filters, (filterItem) => {
      const columnName = filterItem.columns[0].fieldName;
      return _.includes(drilldowns, columnName);
    });

    return drillDownFilters;
  };

  onClickHierarchy = () => {
    this.setState({ showHierarchyListContainer: !this.state.showHierarchyListContainer });
  };

  onClickDrilldownReset = () => {
    const { drilldowns, filters, onDrilldownReset, datasetUid, columns } = this.props;
    const normalFilters = _.filter(filters, (filterItem) => !filterItem.isDrilldown);
    const drilldownFilters = _.map(drilldowns, (drilldown) => {
      const filterForColumn = _.find(filters, (filterItem) => filterItem.columns[0].fieldName === drilldown);
      const isHidden = _.get(filterForColumn, 'isHidden', false);
      const defaultFilterForColumn = getNoopFilter(
        { [datasetUid]: _.find(columns, ['fieldName', drilldown]) },
        true
      );
      return _.merge({}, defaultFilterForColumn, { isHidden });
    });

    onDrilldownReset([...drilldownFilters, ...normalFilters], _.head(drilldowns));
  };

  onClickDrillUp = () => {
    const { filters, currentDrilldownDimensionColumnName, datasetUid, columns } = this.props;

    const searchColumn = ['columns[0].fieldName', currentDrilldownDimensionColumnName];
    const currentDrilldownDimensionFilter = _.find(this.getDrillDownFilters(), searchColumn);

    let previousDrilldownDimension;
    if (_.isEmpty(currentDrilldownDimensionFilter.arguments)) {
      previousDrilldownDimension = this.getPreviousDrilldownDimension();
    } else {
      previousDrilldownDimension = currentDrilldownDimensionColumnName;
    }

    const newFilters = _.map(filters, (filterItem) => {
      const columnName = filterItem.columns[0].fieldName;
      if (columnName === previousDrilldownDimension) {
        const isHidden = _.get(filterItem, 'isHidden', false);

        const defaultFilterForColumn = getNoopFilter(
          { [datasetUid]: _.find(columns, ['fieldName', previousDrilldownDimension]) },
          true
        );
        return _.merge({}, defaultFilterForColumn, { isHidden });
      }
      return filterItem;
    });

    this.props.onDrilldownChange(newFilters, previousDrilldownDimension);
  };

  onDimensionChange = (nextDrilldownDimension) => {
    this.props.onDrilldownChange(this.props.filters, nextDrilldownDimension);
  };

  renderFlyoutContent(flyoutId, title) {
    return (
      <div id={flyoutId} className="flyout flyout-right flyout-hidden">
        <section className="flyout-content">
          <h3 className="flyout-header">
            <div className="visualization-type-title">{title}</div>
          </h3>
        </section>
      </div>
    );
  }

  renderButtonAndFlyout({ disabled, iconName, name, onClickButton, title, showFlyout = true }) {
    const buttonAttributes = {
      'aria-label': title,
      'data-flyout': name,
      id: name,
      className: classNames('btn btn-default btn-sm', { 'btn-disabled-light': disabled }),
      disabled,
      onClick: onClickButton
    };

    return (
      <Button {...buttonAttributes}>
        <SocrataIcon name={iconName} />
        {showFlyout && this.renderFlyoutContent(name, title)}
      </Button>
    );
  }

  renderHierarchyList = () => {
    const { currentDrilldownDimensionColumnName, getDisplayNameForColumn, drilldowns } = this.props;

    return _.map(drilldowns, (drilldownColumnName, index) => {
      const listClasses = classNames('drill-down-list', {
        active: drilldownColumnName === currentDrilldownDimensionColumnName
      });
      const listAttributes = {
        className: listClasses,
        key: index,
        onClick: () => this.onDimensionChange(drilldownColumnName)
      };

      return <li {...listAttributes}>{getDisplayNameForColumn(drilldownColumnName)}</li>;
    });
  };

  renderHierarchyListContainer() {
    const hierarchyListClasses = classNames('hierarchy-container', {
      show: this.state.showHierarchyListContainer
    });

    return (
      <div className={hierarchyListClasses}>
        <div className="hierarchy-list">
          <ul>{this.renderHierarchyList()}</ul>
        </div>
      </div>
    );
  }

  renderDrillUpButton() {
    const drillUpButtonAttributes = {
      disabled: this.isFirstDrilldownDimension(),
      iconName: 'arrow-up2',
      name: 'drillUp',
      onClickButton: this.onClickDrillUp,
      title: I18n.t('drill_up.title', { scope })
    };

    return this.renderButtonAndFlyout(drillUpButtonAttributes);
  }

  renderHierarchyButton() {
    const hierarchyButtonAttributes = {
      disabled: false,
      iconName: 'hierarchy',
      name: 'hierarchy',
      onClickButton: this.onClickHierarchy,
      showFlyout: !this.state.showHierarchyListContainer,
      title: I18n.t('hierarchy.title', { scope })
    };

    return this.renderButtonAndFlyout(hierarchyButtonAttributes);
  }

  renderResetButton() {
    const { isEditMode, shouldDisableResetButton } = this.props;
    const drilldownFiltersWithArguments = _.filter(this.getDrillDownFilters(), (filterItem) => {
      return !_.isNil(filterItem.arguments);
    });
    const disabledResetButton = isEditMode
      ? this.isFirstDrilldownDimension() && _.isEmpty(drilldownFiltersWithArguments)
      : shouldDisableResetButton;
    const title = I18n.t('reset.title', { scope });
    const resetButtonAttributes = {
      disabled: disabledResetButton,
      iconName: 'undo',
      name: 'reset',
      onClickButton: this.onClickDrilldownReset,
      title
    };

    return this.renderButtonAndFlyout(resetButtonAttributes);
  }

  renderButtonGroup() {
    return (
      <div className="btn-group drill-down-btn-group">
        {this.renderDrillUpButton()}
        {this.renderHierarchyButton()}
        {this.renderResetButton()}
        {this.renderHierarchyListContainer()}
      </div>
    );
  }

  render() {
    return this.renderButtonGroup();
  }
}

DrilldownPane.propTypes = {
  currentDrilldownDimensionColumnName: PropTypes.string,
  drilldowns: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(FilterPropType),
  onDrilldownChange: PropTypes.func,
  onDrilldownReset: PropTypes.func,
  isEditMode: PropTypes.bool,
  shouldDisableResetButton: PropTypes.bool,
  getDisplayNameForColumn: PropTypes.func
};

DrilldownPane.defaultProps = {
  onDrilldownChange: _.noop,
  onDrilldownReset: _.noop,
  isEditMode: true,
  getDisplayNameForColumn: _.noop
};
