import { useEffect, useState } from 'react';
import { createReportWorker } from '../worker/createWorker';
import { WorkerOutputMessage } from '../types';

type USE_PDF_WORKER_ARGS = {
  onError?: (error: string) => void;
  onMessage: (message: MessageEvent<WorkerOutputMessage>) => void;
};

export const usePdfWorker = (props: USE_PDF_WORKER_ARGS) => {
  const { onError, onMessage } = props;
  const [worker, setWorker] = useState<Worker | null>(null);
  const [_, setIsError] = useState<boolean>(false);

  useEffect(() => {
    let newWorker: Worker | null = null;

    async function initWorker() {
      try {
        const { worker: createdWorker } = await createReportWorker();
        createdWorker.onmessage = onMessage;
        setWorker(createdWorker);

        newWorker = createdWorker; // Capture reference for cleanup
      } catch (error) {
        setIsError(true);
        onError?.(error instanceof Error ? error.message : 'Worker initialization failed');
        return () => {};
      }
    }

    initWorker();

    return () => {
      if (newWorker) {
        newWorker.terminate();
      }
    };
  }, [onError, onMessage]);

  return worker;
};
