// Vendor Imports
import { bindAll, isEqual, noop, uniqueId as _uniqueId, cloneDeep } from 'lodash';
import React, { Component, HTMLAttributes } from 'react';
import { ForgePopup, ForgeTooltip } from '@tylertech/forge-react';
import { IPopupComponent } from '@tylertech/forge';
import classNames from 'classnames';

// Project Imports
import SocrataIcon, { IconName } from '../SocrataIcon';
import FilterEditor from './FilterEditor';
import FilterConfig from './FilterConfig';
import * as BaseFilter from './lib/Filters/BaseFilter';
import I18n from 'common/i18n';
import { isInsideFlannels, defaultRelativeDateOptions } from './lib/helpers';

// Constants
import { SoqlFilter } from './SoqlFilter';
import { DataProvider, RelativeDatePeriod, FilterBarPendoIds } from 'common/components/FilterBar/types';
import { ParameterConfiguration } from 'common/types/reportFilters';
import { Key } from 'common/types/keyboard/key';

import { getFilterHumanText } from './lib/Filters';

export interface FilterItemProps {
  allFilters: SoqlFilter[];
  allParameters: ParameterConfiguration[];
  constraints?: {
    geoSearch: {
      boundary: number[];
    };
  };
  appliedFilter: SoqlFilter;
  columns: BaseFilter.FilterColumnsMap;
  disabled?: boolean;
  relativeDateOptions?: RelativeDatePeriod[];
  isReadOnly?: boolean;
  dataProvider: DataProvider[];
  onRemove: () => void;
  onUpdate: (filter: SoqlFilter) => void;
  onToggleControl: (value: boolean) => void;
  showNullsAsFalse?: boolean;
  showRemoveButtonInFilterEditor?: boolean;
  pendoIds?: FilterBarPendoIds;
}

interface FilterItemState {
  isControlOpen: boolean;
  isConfigOpen: boolean;
  columnStatsLoaded: boolean;
  uniqueId: string;
  columnsWithColumnStats: BaseFilter.FilterColumnsMap;
}

export class FilterItem extends Component<FilterItemProps, FilterItemState> {
  static defaultProps = {
    onToggleControl: noop
  };

  private _isMounted: boolean;
  private filterControlToggle = React.createRef<HTMLDivElement>();
  private filterControlPopupRef = React.createRef<IPopupComponent & HTMLElement>();
  private filterConfig = React.createRef<FilterConfig>();
  private filterConfigToggle = React.createRef<HTMLDivElement>();
  private filterConfigPopupRef = React.createRef<IPopupComponent & HTMLElement>();
  private controlContainer = React.createRef<HTMLDivElement>();
  private configContainer = React.createRef<HTMLDivElement>();

  bodyClickHandler: (e: MouseEvent) => void;
  bodyEscapeHandler: (e: KeyboardEvent) => void;

  getDataProvider(props: FilterItemProps) {
    return props.dataProvider[0];
  }

  constructor(props: FilterItemProps) {
    super(props);
    this.state = {
      isControlOpen: false,
      isConfigOpen: false,
      columnStatsLoaded: false,
      uniqueId: _uniqueId('filter-item-'),
      columnsWithColumnStats: this.props.columns
    };

    bindAll(this, [
      'preventScrollWithSpaceBar',
      'onKeyUpConfig',
      'onKeyUpControl',
      'onRemove',
      'onUpdate',
      'renderFilterConfig',
      'renderFilterConfigToggle',
      'renderFilterControlToggle',
      'toggleConfig',
      'toggleControl'
    ]);
  }

  componentDidMount() {
    // To prevent a race condition updating state on column stats when fetching is completed after the component is unmounted
    this._isMounted = true;
    this.updateColumnsWithStats();
    this.bodyClickHandler = (event) => {
      const { isConfigOpen } = this.state;
      if (!isConfigOpen) return;

      // Avoid closing flannels if the click is inside any of these refs.
      const flannelElements = [this.filterConfig.current, this.filterConfigToggle.current] as (
        | HTMLDivElement
        | FilterConfig
        | null
      )[];

      const clickInsideFlannels = isInsideFlannels(event, flannelElements);

      // If none of the flannelElements contain event.target, close all the flannels.
      if (!clickInsideFlannels) {
        this.closeConfig();
      }
    };

    this.bodyEscapeHandler = (event) => {
      const { isConfigOpen, isControlOpen } = this.state;

      if (event.key === Key.Escape) {
        if (isConfigOpen && this.filterConfigToggle.current) {
          this.closeConfig();
          this.filterConfigToggle.current.focus();
        }
        if (isControlOpen && this.filterControlToggle.current) {
          this.closeControl();
          this.filterControlToggle.current.focus();
        }
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
    document.body.addEventListener('keyup', this.bodyEscapeHandler);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.body.removeEventListener('click', this.bodyClickHandler);
    document.body.removeEventListener('keyup', this.bodyEscapeHandler);
  }

  componentDidUpdate(nextProps: FilterItemProps) {
    if (!isEqual(nextProps.appliedFilter.columns, this.props.appliedFilter.columns)) {
      // If the set of columns included in the filter has changed or the filter config has
      // changed, refetch column stats and update the filter in state
      this.updateColumnsWithStats();
    }
  }

  columnStatLoaded = (): boolean => {
    if (BaseFilter.isColumnStatRequired(this.props.appliedFilter)) {
      return this.state.columnStatsLoaded;
    }
    return true;
  };

  /**
   * Keep the columnsWithColumnStats in sync with what's passed through props.
   * Fetches column stats for relevant filter types as well.
   */
  updateColumnsWithStats = async (props = this.props): Promise<void> => {
    const { appliedFilter, columns, dataProvider } = props;

    if (!columns || !this._isMounted) return;

    if (!BaseFilter.isColumnStatRequired(appliedFilter)) {
      this.setState({ columnsWithColumnStats: columns });
      return;
    }

    try {
      this.setState({ columnStatsLoaded: false });
      const columnsWithColumnStats = await BaseFilter.getAllColumnStats(dataProvider, columns);
      if (this._isMounted) {
        this.setState({ columnStatsLoaded: true, columnsWithColumnStats });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ columnStatsLoaded: false });
      }
      if (columns) {
        console.error(
          `Soql like cachedContents failed for one or more of data sources: ${Object.keys(columns).join(
            ', '
          )}`
        );
      }
      console.error(error);
    }
  };

  preventScrollWithSpaceBar(event: React.KeyboardEvent): void {
    if (event.key === Key.Space) {
      event.preventDefault(); // Prevents page scrolling with a space key
    }
  }

  onKeyUpControl(event: React.KeyboardEvent): void {
    if (event.key === Key.Space || event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleControl();
    }
  }

  onKeyUpConfig(event: React.KeyboardEvent): void {
    if (event.key === Key.Space || event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleConfig();
    }
  }

  onUpdate(newFilter: SoqlFilter, { shouldCloseControl = true } = {}) {
    this.props.onUpdate(newFilter);
    if (shouldCloseControl && this.filterControlToggle.current) {
      this.filterControlToggle.current.focus();
    }
  }

  onRemove(): void {
    this.props.onRemove();
  }

  toggleControl(): void {
    const { onToggleControl } = this.props;
    onToggleControl(!this.state.isControlOpen);

    this.setState({
      isControlOpen: !this.state.isControlOpen,
      isConfigOpen: false
    });
  }

  toggleConfig(): void {
    const { onToggleControl } = this.props;
    onToggleControl(false);

    this.setState({
      isControlOpen: false,
      isConfigOpen: !this.state.isConfigOpen
    });
  }

  closeConfig(): void {
    const { isConfigOpen } = this.state;
    if (!isConfigOpen) return;

    this.setState({
      isConfigOpen: false
    });
  }

  closeControl(): void {
    this.setState({
      isControlOpen: false
    });
  }

  renderFilterConfig(): JSX.Element | null {
    const { appliedFilter, onUpdate } = this.props;
    const { isConfigOpen } = this.state;

    if (!isConfigOpen) {
      return null;
    }

    const configProps = {
      filter: appliedFilter,
      onUpdate
    };

    return (
      <ForgePopup
        ref={this.filterConfigPopupRef}
        open={isConfigOpen}
        options={{ placement: 'bottom-end' }}
        targetElementRef={this.filterConfigToggle}
      >
        <FilterConfig {...configProps} ref={this.filterConfig} />
      </ForgePopup>
    );
  }

  renderFilterConfigToggle(): JSX.Element | null {
    const { isReadOnly, disabled } = this.props;
    const { isConfigOpen } = this.state;

    if (isReadOnly || disabled) {
      return null;
    }

    const toggleProps: React.HTMLAttributes<HTMLDivElement> = {
      className: classNames('filter-config-toggle btn-default', {
        active: isConfigOpen
      }),
      'aria-label': I18n.t('shared.components.filter_bar.configure_filter'),
      tabIndex: 0,
      role: 'button',
      onClick: this.toggleConfig,
      onKeyDown: this.preventScrollWithSpaceBar,
      onKeyUp: this.onKeyUpConfig
    };

    return (
      <div {...toggleProps} ref={this.filterConfigToggle}>
        <span className="kebab-icon">
          <SocrataIcon name={IconName.Kebab} />
        </span>
      </div>
    );
  }

  renderFilterControl(): JSX.Element {
    const { isControlOpen, uniqueId, columnsWithColumnStats } = this.state;
    const {
      appliedFilter,
      constraints,
      allFilters,
      allParameters,
      relativeDateOptions,
      isReadOnly,
      showNullsAsFalse,
      dataProvider,
      showRemoveButtonInFilterEditor = false
    } = this.props;

    const isOpen = isControlOpen && this.columnStatLoaded();

    const relativeDateOptionsWithOverrides = relativeDateOptions
      ? relativeDateOptions
      : defaultRelativeDateOptions();

    const filterProps = {
      appliedFilter: cloneDeep(appliedFilter),
      columns: cloneDeep(columnsWithColumnStats),
      constraints,
      allFilters,
      allParameters,
      relativeDateOptions: relativeDateOptionsWithOverrides,
      isReadOnly,
      showNullsAsFalse,
      dataProvider,
      onRemove: this.onRemove,
      onUpdate: this.onUpdate,
      popupRef: this.filterControlPopupRef,
      showRemoveButtonInFooter: showRemoveButtonInFilterEditor
    };

    const popupOptions = {
      placement: 'bottom-end', // forge-popup will adjust placement if needed
      closeCallback: () => {
        this.closeControl();
        // Wrapped in setTimeout to wait for rerenders to complete
        setTimeout(() => {
          this.filterControlToggle.current?.focus();
        });
      }
    };

    return (
      <ForgePopup
        ref={this.filterControlPopupRef}
        open={isOpen}
        targetElementRef={this.filterControlToggle}
        options={popupOptions}
      >
        <div id={`${uniqueId}-control`}>
          <FilterEditor {...filterProps} />
        </div>
      </ForgePopup>
    );
  }

  renderFilterControlToggle(): JSX.Element {
    const { appliedFilter, columns, disabled, showNullsAsFalse } = this.props;
    const { isControlOpen, uniqueId, columnsWithColumnStats } = this.state;
    const isFilterDisabled = disabled || appliedFilter.isOverridden;
    const filterHumanText = getFilterHumanText(appliedFilter, columnsWithColumnStats, showNullsAsFalse);

    const toggleProps: HTMLAttributes<HTMLDivElement> = {
      className: classNames('filter-control-toggle btn-default', {
        active: isControlOpen,
        disabled: isFilterDisabled
      }),
      'aria-label': `${I18n.t('shared.components.filter_bar.filter')} ${BaseFilter.getFilterName(
        appliedFilter,
        columns
      )} - ${filterHumanText}`,
      tabIndex: 0
    };

    if (!isFilterDisabled) {
      toggleProps.role = 'button';
      toggleProps.onClick = this.toggleControl;
      toggleProps.onKeyDown = this.preventScrollWithSpaceBar;
      toggleProps.onKeyUp = this.onKeyUpControl;
    }
    // Only add aria-controls when the control is open
    if (isControlOpen) {
      toggleProps['aria-controls'] = `${uniqueId}-control`;
    }

    return (
      <div {...toggleProps} ref={this.filterControlToggle}>
        {filterHumanText}
        {isFilterDisabled && (
          <ForgeTooltip id="filter-tooltip" position={'bottom'}>
            {I18n.t('shared.components.filter_bar.filter_tooltip')}
          </ForgeTooltip>
        )}
        <span className="arrow-down-icon">
          <SocrataIcon name={IconName.ArrowDown} aria-hidden={true} />
        </span>
      </div>
    );
  }

  render() {
    const { appliedFilter, columns, pendoIds } = this.props;
    const CONFIG = 'config';
    const CONTROL = 'control';

    const closeIfBlurred = noop;
    const filterName = BaseFilter.getFilterName(appliedFilter, columns);

    // NOTE: You can't use ref'd values right away.
    //       Using a string constant to ID the
    //       container. There is probably a better
    //       way to do this.
    return (
      <div className="filter-bar-filter">
        <label className="filter-control-label">
          {filterName}
          {!this.columnStatLoaded() && <span className="spinner-default column-stat-spinner" />}
        </label>
        <div
          id={pendoIds?.filterParameterControlContainer}
          data-testid="filter-bar-filter-control-container"
          className="filter-control-container"
          ref={this.controlContainer}
          onBlur={() => closeIfBlurred(CONTROL)}
        >
          {this.renderFilterControlToggle()}
          {this.renderFilterControl()}
        </div>
        <div
          data-testid="filter-bar-filter-config-container"
          className="filter-config-container"
          ref={this.configContainer}
          onBlur={() => closeIfBlurred(CONFIG)}
        >
          {/* Only rendered for editing the filter bar, this is the action menu for the filter item */}
          {this.renderFilterConfigToggle()}
          {this.renderFilterConfig()}
        </div>
      </div>
    );
  }
}

export default FilterItem;
