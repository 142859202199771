import { SoQLException } from 'common/soql/compiler-api';
import { View } from 'common/types/view';
import { Option } from 'ts-option';
import {
  AnalyzedAst,
  SoQLRendering,
  UnAnalyzedAst,
  RunnableSoQLRendering,
  SoQLType,
  BinaryTree,
  Expr,
  TypedSelect
} from './soql';

export enum CompilationStatus {
  Staged = 'staged',
  Started = 'started',
  Failed = 'failed',
  Succeeded = 'succeeded'
}

type FunctionNameWithCaseFolded = [string, string];
type ColumnNameWithCaseFolded = [string, string];
type SoQLTypeWithCaseFolded = [string, SoQLType];

export const getFunctionName = (fn: FunctionNameWithCaseFolded) => fn[1];
export const getColumnName = (cn: ColumnNameWithCaseFolded) => cn[1];
export const getSoQLType = (st: SoQLTypeWithCaseFolded) => st[1];

interface Position {
  row: number;
  column: number;
  text?: string;
}

export interface AggregateInUngroupedContext {
  type: 'aggregate-in-ungrouped-context';
  english: string;
  function: FunctionNameWithCaseFolded;
  clause: string;
  position: Position;
}
export interface ColumnNotInGroupBys {
  type: 'column-not-in-group-bys';
  english: string;
  column: ColumnNameWithCaseFolded;
  position: Position;
}

export interface RepeatedException {
  type: 'repeated-exclusion';
  english: string;
  name: ColumnNameWithCaseFolded;
  position: Position;
}
export interface DuplicateAlias {
  type: 'duplicate-alias';
  english: string;
  name: ColumnNameWithCaseFolded;
  position: Position;
}
export interface NoSuchColumn {
  type: 'no-such-column';
  english: string;
  name: ColumnNameWithCaseFolded;
  position: Position;
}
export interface NoSuchTable {
  type: 'no-such-table';
  english: string;
  qualifier: string;
  position: Position;
}
export interface CircularAliasDefinition {
  type: 'circular-alias';
  english: string;
  name: ColumnNameWithCaseFolded;
  position: Position;
}

export interface UnexpectedEscape {
  type: 'unexpected-escape';
  english: string;
  char: string;
  position: Position;
}
export interface BadUnicodeEscapeCharacter {
  type: 'bad-unicode-escape';
  english: string;
  char: string;
  position: Position;
}
export interface UnicodeCharacterOutOfRange {
  type: 'unicode-character-out-of-range';
  english: string;
  value: number;
  position: Position;
}
export interface UnexpectedCharacter {
  type: 'unexpected-character';
  english: string;
  char: string;
  position: Position;
}
export interface UnexpectedEOF {
  type: 'unexpected-eof';
  english: string;
  position: Position;
}

export interface UnterminatedString {
  type: 'unterminated-string';
  english: string;
  position: Position;
}

export interface BadParse {
  type: 'bad-parse';
  english: string;
  message: string;
  position: Position;
}

export interface NoSuchFunction {
  type: 'no-such-function';
  english: string;
  name: FunctionNameWithCaseFolded;
  arity: number;
  position: Position;
}
export interface TypeMismatch {
  type: 'type-mismatch';
  english: string;
  name: FunctionNameWithCaseFolded;
  actual: SoQLTypeWithCaseFolded;
  position: Position;
}
export interface AmbiguousCall {
  type: 'ambiguous-call';
  english: string;
  name: FunctionNameWithCaseFolded;
  position: Position;
}

export interface NonBooleanWhere {
  type: 'non-boolean-where';
  english: string;
  typ: SoQLTypeWithCaseFolded;
  position: Position;
}
export interface NonGroupableGroupBy {
  type: 'non-groupable-group-by';
  english: string;
  typ: SoQLTypeWithCaseFolded;
  position: Position;
}
export interface NonBooleanHaving {
  type: 'non-boolean-having';
  english: string;
  typ: SoQLTypeWithCaseFolded;
  position: Position;
}
export interface UnorderableOrderBy {
  type: 'unorderable-order-by';
  english: string;
  typ: SoQLTypeWithCaseFolded;
  position: Position;
}

export type CompilationFailedDetails =
  | AggregateInUngroupedContext
  | ColumnNotInGroupBys
  | RepeatedException
  | DuplicateAlias
  | NoSuchColumn
  | NoSuchTable
  | CircularAliasDefinition
  | UnexpectedEscape
  | BadUnicodeEscapeCharacter
  | UnicodeCharacterOutOfRange
  | UnexpectedCharacter
  | UnexpectedEOF
  | UnterminatedString
  | BadParse
  | NoSuchFunction
  | TypeMismatch
  | AmbiguousCall
  | NonBooleanWhere
  | NonGroupableGroupBy
  | NonBooleanHaving
  | UnorderableOrderBy;

export interface AnonymousSource {
  type: 'anonymous';
  position: Position;
}

export interface SyntheticSource {
  type: 'synthetic';
}

export interface SavedSource {
  type: 'saved';
  position: Position;
  scopedResourceName: any;
}

export const isSyntheticSource = (s: Source): s is SyntheticSource => s.type === 'synthetic';
export type Source = AnonymousSource | SyntheticSource | SavedSource;
export const extractPositionFromError = (error: CompilationFailedDetails | AnalysisFailedDetails): Position | undefined => {
  if ('source' in error) {
    if (isSyntheticSource(error.source)) {
      return undefined;
    } else {
      return error.source.position;
    }
  } else {
    return error.position;
  }
};

export interface AnalyzerError {
  data?: Record<string, any>;
  english: string;
  source: Source;
  type:
  | 'soql.tablefinder.dataset-not-found'
  | 'soql.tablefinder.permission-denied'
  | 'soql.tablefinder.recursive-query'
  | 'soql.parser.unexpected-escape'
  | 'soql.parser.bad-unicode-escape'
  | 'soql.parser.unicode-character-out-of-range'
  | 'soql.parser.unexpected-character'
  | 'soql.parser.unexpected-end-of-input'
  | 'soql.parser.unterminated-string'
  | 'soql.parser.expected-token'
  | 'soql.parser.expected-leaf-query'
  | 'soql.parser.unexpected-star-select'
  | 'soql.parser.unexpected-system-star-select'
  | 'soql.analyzer.expected-boolean'
  | 'soql.analyzer.incorrect-udf-parameter-count'
  | 'soql.analyzer.distinct-on-not-prefix-of-order-by'
  | 'soql.analyzer.order-by-must-be-selected-when-distinct'
  | 'soql.analyzer.invalid-group-by'
  | 'soql.analyzer.parameters-for-non-udf'
  | 'soql.analyzer.table-alias-already-exists'
  | 'soql.analyzer.from-required'
  | 'soql.analyzer.from-forbidden'
  | 'soql.analyzer.from-this-without-context'
  | 'soql.analyzer.table-operation-type-mismatch'
  | 'soql.analyzer.literal-not-allowed-in-group-by'
  | 'soql.analyzer.literal-not-allowed-in-order-by'
  | 'soql.analyzer.literal-not-allowed-in-distinct-on'
  | 'soql.analyzer.aggregate-function-not-allowed'
  | 'soql.analyzer.ungrouped-column-reference'
  | 'soql.analyzer.window-function-not-allowed'
  | 'soql.analyzer.parameterless-table-function'
  | 'soql.analyzer.illegal-this-reference'
  | 'soql.analyzer.reserved-table-name'
  | 'soql.analyzer.typechecker.unordered-order-by'
  | 'soql.analyzer.typechecker.no-such-column'
  | 'soql.analyzer.typechecker.unknown-udf-parameter'
  | 'soql.analyzer.typechecker.unknown-user-parameter'
  | 'soql.analyzer.typechecker.no-such-function'
  | 'soql.analyzer.typechecker.type-mismatch'
  | 'soql.analyzer.typechecker.requires-window'
  | 'soql.analyzer.typechecker.illegal-start-frame-bound'
  | 'soql.analyzer.typechecker.illegal-end-frame-bound'
  | 'soql.analyzer.typechecker.mismatched-frame-bound'
  | 'soql.analyzer.typechecker.non-aggregate-function'
  | 'soql.analyzer.typechecker.non-window-function'
  | 'soql.analyzer.typechecker.distinct-with-over'
  | 'soql.analyzer.typechecker.groups-requires-order-by'
  | 'soql.analyzer.alias.repeated-exclusion'
  | 'soql.analyzer.alias.duplicate-alias'
  | 'soql.analyzer.alias.circular-alias-definition';
}

export type AnalysisFailedDetails =
  | BadParse
  | UnexpectedEOF
  | AnalyzerError;

export interface SimpleCompilationStaged {
  type: CompilationStatus.Staged;
}

export interface SimpleCompilationStarted {
  type: CompilationStatus.Started;
}

export interface SimpleCompilationFailed {
  type: CompilationStatus.Failed;
  soql_exception: CompilationFailedDetails;
}

export interface SimpleCompilationSucceeded {
  type: CompilationStatus.Succeeded;
}

export type ExpressionCompilationStarted = SimpleCompilationStarted & {
  expr: string;
};
export type ExpressionCompilationFailed = SimpleCompilationFailed;
export type ExpressionCompilationSucceeded = SimpleCompilationSucceeded & {
  parsed: Expr & { result_type: SoQLType };
  expr: string;
};

export type QueryCompilationStaged = SimpleCompilationStaged & {
  ast: BinaryTree<UnAnalyzedAst>;
  tableAliases: TableAliases;
};
export type QueryCompilationStarted = SimpleCompilationStarted & {
  ast: Option<BinaryTree<UnAnalyzedAst>>;
  ref: string;
  text: Option<string>;
};
export type QueryCompilationFailed = SimpleCompilationFailed & {
  text: Option<string>;
  unanalyzed: Option<BinaryTree<UnAnalyzedAst>>;
  tableAliases: TableAliases;
  views: Option<ViewContext>;
};
export type ViewContext = { [ident: string]: View };
export type TableAliases = {
  realTables: Record<string, string>; // This should be alias->name.
  virtualTables: string[];
};
export const emptyTableAliases = (): TableAliases => ({ realTables: {}, virtualTables: [] });
export type QueryCompilationSucceeded = SimpleCompilationSucceeded & {
  analyzed: BinaryTree<AnalyzedAst>;
  unanalyzed: BinaryTree<UnAnalyzedAst>;
  rendering: SoQLRendering;
  runnable: RunnableSoQLRendering;
  tableAliases: TableAliases;
  views: ViewContext;
  pageSize: number;
  currentPage: number;
  text: Option<string>;
};

export interface SimpleAnalysisFailed {
  type: CompilationStatus.Failed;
  soql_exception: AnalysisFailedDetails;
}
export interface OutputColumn {
  name: string;
  type: SoQLType | null;
  is_synthetic: boolean;
}
export interface QueryAnalysisFailed extends SimpleAnalysisFailed {
  views: Option<ViewContext>;
  attemptedAst: Option<BinaryTree<TypedSelect>> // does not come from analyzer, added by reducer
}
export interface QueryAnalysisSucceeded {
  type: CompilationStatus.Succeeded;
  text: string;
  formattedText: string;
  ast: BinaryTree<TypedSelect>;
  outputSchema: OutputColumn[];
  tableAliases: TableAliases;
  views: ViewContext;
}

export type SimpleCompilationResult =
  | SimpleCompilationStaged
  | SimpleCompilationStarted
  | SimpleCompilationSucceeded
  | SimpleCompilationFailed
  | SimpleAnalysisFailed;

export type QueryCompilationResult =
  | QueryCompilationStaged
  | QueryCompilationStarted
  | QueryCompilationFailed
  | QueryCompilationSucceeded;

export type QueryAnalysisResult =
  | QueryAnalysisSucceeded
  | QueryAnalysisFailed;

export type ExpressionCompilationResult =
  | ExpressionCompilationStarted
  | ExpressionCompilationSucceeded
  | ExpressionCompilationFailed;

export function isCompilationStarted<T extends SimpleCompilationStarted>(
  cr: SimpleCompilationResult | null
): cr is T {
  return !!(cr && cr.type === CompilationStatus.Started);
}
export function isCompilationSucceeded<T extends SimpleCompilationSucceeded>(
  cr: SimpleCompilationResult | null
): cr is T {
  return !!(cr && cr.type === CompilationStatus.Succeeded);
}
export function isCompilationFailed<T extends SimpleCompilationFailed>(
  cr: SimpleCompilationResult | null
): cr is T {
  return !!(cr && cr.type === CompilationStatus.Failed);
}
