// Vendor Imports
import $ from 'jquery';
import _ from 'lodash';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { SoQLType } from 'common/types/soql';

// Project Imports
import CategoricalDataManager from './dataProviders/CategoricalDataManager';
import { getSoqlVifValidator } from './dataProviders/SoqlVifValidator';
import TimeDataManager from './dataProviders/TimeDataManager';
import { getColumnFormats } from './helpers/ColumnFormattingHelpers';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import SvgTimelineChart from './views/SvgTimelineChart';
import { InlineDataProvider } from 'common/visualizations/dataProviders';
import MetadataProvider, {
  getDisplayableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import { generateSummaryTableVif } from './VisualizationCommon';

// Constants
import { SERIES_TYPE_FLYOUT, WINDOW_RESIZE_RERENDER_DELAY } from './views/SvgConstants';

$.fn.socrataSvgTimelineChart = function (originalVif, options) {
  originalVif = migrateVif(originalVif);

  const $element = $(this);
  const visualization = new SvgTimelineChart($element, originalVif, options);
  let rerenderOnResizeTimeout;

  /**
   * Event handling
   */

  function attachApiEvents() {
    // Destroy on (only the first) 'SOCRATA_VISUALIZATION_DESTROY' event.
    $element.one('SOCRATA_VISUALIZATION_DESTROY', function () {
      clearTimeout(rerenderOnResizeTimeout);
      visualization.destroy();
      detachInteractionEvents();
      detachApiEvents();
    });

    $(window).on('resize', handleWindowResize);

    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function attachInteractionEvents() {
    $element.on('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', handleFlyout);
  }

  function detachApiEvents() {
    $(window).off('resize', handleWindowResize);

    $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function detachInteractionEvents() {
    $element.off('SOCRATA_VISUALIZATION_TIMELINE_CHART_FLYOUT', handleFlyout);
  }

  function handleWindowResize() {
    clearTimeout(rerenderOnResizeTimeout);

    rerenderOnResizeTimeout = setTimeout(
      visualization.invalidateSize,
      // Add some jitter in order to make sure multiple visualizations are
      // unlikely to all attempt to rerender themselves at the exact same
      // moment.
      WINDOW_RESIZE_RERENDER_DELAY + Math.floor(Math.random() * 10)
    );
  }

  function handleFlyout(event) {
    const payload = event.originalEvent.detail;

    $element[0].dispatchEvent(
      new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
        detail: payload,
        bubbles: true
      })
    );
  }

  function handleRenderVif(event) {
    const newVif = event.originalEvent.detail;
    updateData(migrateVif(newVif));
  }

  function handleError(error) {
    let messages;

    if (window.console && console.error) {
      console.error(error);
    }

    if (error.vifValidatorErrors) {
      messages = error.vifValidatorErrors;
    } else if (error.soqlError) {
      const errorCode = _.get(error, 'soqlError.errorCode');

      messages = errorCode
        ? I18n.t(`shared.visualizations.charts.common.soql_error.${errorCode}`)
        : I18n.t('shared.visualizations.charts.common.error_generic');
    } else if (error.message && error.name === 'exceededMaxRowCount') {
      return visualization.renderGenericError(error.message, 'exceededMaxRowCount');
    } else {
      return visualization.renderGenericError(
        I18n.t('shared.errors.private_or_deleted_asset.message'),
        'privateOrDeletedAsset'
      );
    }

    visualization.renderError(messages);
  }

  async function updateData(newVif) {
    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
    visualization.showBusyIndicator();
    detachInteractionEvents();

    const dataSourceType = _.get(newVif, 'series[0].dataSource.type');
    const inline = dataSourceType === 'socrata.inline'; // inline is when the chart is displayed in measures

    try {
      await $.fn.socrataSvgTimelineChart.validateVif(newVif);

      let datasetMetadata;
      let displayableColumns;
      let newColumns;
      let newComputedColumns;
      let newData;
      let newFlyoutData;
      let newTableVif;

      const seriesVif = _.cloneDeep(newVif);
      seriesVif.series = _.reject(seriesVif.series, (series) => series.type === SERIES_TYPE_FLYOUT);

      const flyoutVif = _.cloneDeep(newVif);
      flyoutVif.series = _.filter(flyoutVif.series, (series) => series.type === SERIES_TYPE_FLYOUT);

      if (inline) {
        newData = inlineDataQuery(visualization, seriesVif);
        newFlyoutData = inlineDataQuery(visualization, flyoutVif);

        displayableColumns = newData.displayableColumns;
        newColumns = newData.displayableColumns;
        newComputedColumns = [];
      } else {
        const domain = _.get(newVif, 'series[0].dataSource.domain');
        const datasetUid = _.get(newVif, 'series[0].dataSource.datasetUid');
        const datasetMetadataProvider = new MetadataProvider({ domain, datasetUid }, true);
        datasetMetadata = await datasetMetadataProvider.getDatasetMetadata();
        newComputedColumns = await datasetMetadataProvider.getFormattedComputedRegionColumns();

        if (visualization.shouldDisplayFilterBar()) {
          newColumns = await datasetMetadataProvider.getDisplayableFilterableColumns({
            datasetMetadata,
            shouldGetColumnStats: false
          });
        }

        displayableColumns = getDisplayableColumns(datasetMetadata);
        const dimensionColumnName = _.get(newVif, 'series[0].dataSource.dimension.columnName');
        const columnFormats = getColumnFormats(displayableColumns);
        const isCalendarDate = isDimensionCalendarDate(dimensionColumnName, columnFormats);
        const precision = _.get(newVif, 'series[0].dataSource.precision');

        const getData =
          isCalendarDate && precision !== 'none' ? TimeDataManager.getData : CategoricalDataManager.getData;

        newData = await getData(seriesVif);
        newFlyoutData = await getData(flyoutVif);

        newData.columnFormats = columnFormats;
        newFlyoutData.columnFormats = columnFormats;
      }

      newTableVif = generateTimelineChartSummaryTableVif(newVif, newData, displayableColumns, newFlyoutData);

      renderVisualization({
        newColumns,
        newComputedColumns,
        newData,
        newFlyoutData,
        newVif,
        newTableVif
      });
    } catch (error) {
      handleError(error);
    }
  }

  function generateTimelineChartSummaryTableVif(newVif, newData, displayableColumns, newFlyoutData) {
    const dimensionName = _.get(
      newVif,
      'series[0].dataSource.summaryTable.dimensionName',
      _.get(newVif, 'series[0].dataSource.dimension.columnName')
    );
    const dimension = _.find(displayableColumns, (column) => dimensionName === column.fieldName);
    const tableDimension = _.cloneDeep(dimension);
    _.set(tableDimension, 'renderTypeName', SoQLType.SoQLTextT);

    const tableData = _.cloneDeep(newData);

    if (tableData.summaryTableRows && tableData.summaryTableRows.length > 0) {
      _.set(tableData, 'rows', tableData.summaryTableRows);
    }

    // Filter out rows with no values at all.
    _.set(
      tableData,
      'rows',
      _.filter(tableData.rows, (row) => {
        // The first entry in the row is the dimension. All other entries are values.
        return row.length > 1 && !_.every(_.slice(row, 1), _.isNull);
      })
    );

    return generateSummaryTableVif(newVif, displayableColumns, tableData, tableDimension, newFlyoutData);
  }

  function isDimensionCalendarDate(dimensionColumnName, columnFormats) {
    const columnFormat = columnFormats[dimensionColumnName];
    return !_.isUndefined(columnFormat) && columnFormat.dataTypeName === 'calendar_date';
  }

  function renderVisualization({
    newColumns,
    newComputedColumns,
    newData,
    newFlyoutData,
    newVif,
    newTableVif
  }) {
    const overMaxRowCount = newData.rows.length > TimeDataManager.MAX_ROW_COUNT;

    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
    visualization.hideBusyIndicator();

    if (overMaxRowCount) {
      const error = formatString(
        I18n.t('shared.visualizations.charts.timeline_chart.error_exceeded_max_row_count'),
        TimeDataManager.MAX_ROW_COUNT
      );
      visualization.renderError(error);
    } else {
      attachInteractionEvents();
      visualization.render({
        newColumns,
        newComputedColumns,
        newData,
        newFlyoutData,
        newVif,
        newTableVif
      });
    }
  }

  /**
   * Actual execution starts here
   */

  attachApiEvents();
  updateData(originalVif);

  return this;
};

/**
 * @param {extends BaseVisualization} visualization
 * @param {Vif} vif
 * @return {{
 *  columns: string[],
 *  rows: InlineDataRows,
 *  columnFormats: ColumnFormat[],
 *  precision: string
 * }}
 */
export function inlineDataQuery(visualization, vif) {
  if (visualization.shouldDisplayFilterBar() && !visualization.isMeasure()) {
    // We don't do client-side filtering, and we also can't fulfill the
    // queries getDisplayableFilterableColumns wants to do (specifically,
    // the columnStats query) to power the filtering UI.
    // Measures are an exception because measure charts can only have read-only global filters.
    throw new Error('Filter bar not supported for visualizations using an inline data source.');
  }

  // Generate default columnFormats for each dimension column.
  const columnFormats = _(vif.series)
    .map((series) => _.get(series, 'dataSource.dimension.columnName'))
    .compact()
    .map((fieldName) => [
      fieldName,
      {
        fieldName,
        dataTypeName: 'calendar_date',
        renderTypeName: 'calendar_date'
      }
    ])
    .fromPairs()
    .value();

  const columns = ['dimension'];

  _.each(vif.series, (series) => {
    const { dataSource, label } = series;
    const { measure } = dataSource;
    const { columnName, columnFormat } = measure;
    const asPercent = _.get(columnFormat, 'format.asPercent', false);

    const columnFormatDefault = {
      fieldName: columnName,
      name: label, // This is important - it sets the flyout label.
      dataTypeName: 'number',
      renderTypeName: 'number'
    };
    if (asPercent) {
      _.set(columnFormatDefault, 'format.precisionStyle', 'percentage');
      _.set(columnFormatDefault, 'format.percentScale', '100');
    }
    columnFormats[columnName] = _.merge(columnFormatDefault, columnFormat);
    columns.push(columnName);
  });

  const inlineDataProvider = new InlineDataProvider(vif);
  // We bypass the TimeDataManager/CategoricalDataManager machinery
  // for the inline data provider case because:
  // a) InlineDataProvider would have to be taught how to do complex SoQL queries, and
  // b) the only user of inline data providers for SvgTimelineChart is KPIs, and it actively
  //    does not need the features TimeDataManager provides (it does its own aggregation).
  // To get away with this, we make a couple assumptions:
  // 1) Each series has exactly one numerical measure.
  // 2) Each series is time-dimensioned (no categorical data).
  // 3) There are no custom column formats.
  // 4) Precision is the same everywhere.
  return {
    columns,
    displayableColumns: inlineDataProvider.getColumns(),
    rows: inlineDataProvider.getRows(),
    summaryTableRows: inlineDataProvider.getSummaryTableRows(),
    columnFormats,
    precision: _.toLower(_.get(vif, 'series[0].dataSource.precision'))
  };
}

/**
 * Checks a VIF for compatibility with this visualization. The intent of this
 * function is to provide feedback while authoring a visualization, not to
 * provide feedback to a developer. As such, messages returned are worded to
 * make sense to a user.
 *
 * Returns a Promise.
 *
 * If the VIF is usable, the promise will resolve.
 * If the VIF is not usable, the promise will reject with an object:
 * {
 *   ok: false,
 *   vifValidatorErrors: Array<String>
 * }
 */
$.fn.socrataSvgTimelineChart.validateVif = (vif) =>
  getSoqlVifValidator(vif).then((validator) => validator.requireAtLeastOneSeries().toPromise());

export default $.fn.socrataSvgTimelineChart;
