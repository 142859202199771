import { TableColumn } from '../types';
import { NormalRow, Row } from './type';
import { chain, isNil } from 'lodash';

type Aggregator = (col: TableColumn, groupRows: NormalRow[], isNumericColumn: boolean) => string | number;
const aggregators: Record<string, Aggregator> = {
  sum: (col: TableColumn, groupRows: Row[]) => {
    return groupRows.reduce((sum, row) => {
      const value = row[col.field!];
      const numValue = parseValue(value);

      return sum + (isNaN(numValue) ? 0 : numValue);
    }, 0);
  },
  avg: (col: TableColumn, groupRows: Row[]) => {
    const sumAndCount = groupRows.reduce(
      (acc, row) => {
        const value = row[col.field!];
        const numValue = parseValue(value);

        // If the value is a valid number, add to the sum and increment the count
        if (!isNaN(numValue)) {
          acc.sum += numValue;
          acc.count += 1;
        }

        return acc;
      },
      { sum: 0, count: 0 }
    );

    return sumAndCount.count > 0 ? sumAndCount.sum / sumAndCount.count : 0;
  },
  min: (col: TableColumn, groupRows: Row[], isNumericColumn: boolean) => {
    const values = groupRows.map((row) => row[col.field!]);

    return isNumericColumn
      ? Math.min(...values.filter((value) => !isNaN(value)))
      : values.reduce((min, current) => (current < min ? current : min), values[0]);
  },
  max: (col: TableColumn, groupRows: Row[], isNumericColumn: boolean) => {
    const values = groupRows.map((row) => row[col.field!]);

    return isNumericColumn
      ? Math.max(...values.filter((value) => !isNaN(value)))
      : values.reduce((max, current) => (current > max ? current : max), values[0]);
  },
  count: (col: TableColumn, groupRows: Row[], isNumericColumn: boolean) => {
    const count = chain(groupRows)
      .filter((row) => !isNil(row[col.field]))
      .size()
      .value();
    return isNumericColumn ? count : count.toString();
  },
  default: (col: TableColumn, groupRows: Row[]) => {
    return groupRows.length;
  }
};

export const aggregateRows = (rows: Row[], columnDefs: TableColumn[]) => {
  const normalRows: NormalRow[] = rows.filter((r) => r._rowType === undefined);
  return columnDefs
    .filter((col) => col.aggFunc)
    .reduce((hsh: Partial<NormalRow>, col) => {
      if (col.field) {
        hsh[col.field] = aggregateColumn(col, normalRows);
      }
      return hsh;
    }, {});
};

export const buildTotalsRow = (rows: Row[], columnDefs: TableColumn[]) => {
  return {
    _rowType: 'total',
    ...aggregateRows(rows, columnDefs)
  };
};

const aggregateColumn = (col: TableColumn, groupRows: NormalRow[]): number | string => {
  const isNumericColumn = col.type === 'number';
  const aggregator = aggregators[col.aggFunc!.toString()] || aggregators.default;

  return aggregator(col, groupRows, isNumericColumn);
};

const parseValue = (value: string | number) => {
  return typeof value === 'number' ? value : parseFloat(value);
};
