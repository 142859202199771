// Vendor Imports
import { unionWith } from 'lodash';
import React from 'react';
import { ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { IListItemSelectEventData } from '@tylertech/forge/esm/list';

// Project Imports
import { DataSourceCCVs, ParameterConfiguration } from 'common/types/reportFilters';
import { SpecificValue } from 'common/types/clientContextVariable';
import FilterFooter, { FilterFooterProps } from './FilterFooter';
import { SoQLType } from 'common/types/soql';
import { formatDate } from 'common/dates';

export interface ParameterDropdownProps {
  clientContextVariables?: DataSourceCCVs;
  parameter: ParameterConfiguration;
  onUpdate: (value: string) => void;
  onReset: () => void;
}

const ParameterDropdown = (props: ParameterDropdownProps) => {
  const { clientContextVariables, onReset, onUpdate, parameter } = props;

  const onSelectParameterOverride = (event: CustomEvent<IListItemSelectEventData>) => {
    onUpdate(event.detail.value);
  };

  const onResetParameter = (event: React.MouseEvent) => {
    onReset();
  };

  const dropdownValue = (value: SpecificValue) => {
    return (
      <ForgeListItem
        key={value.value}
        value={value.value}
        data-testid={`${value.value}-parameter-option`}
        on-forge-list-item-select={onSelectParameterOverride}
      >
        {value.displayName}
      </ForgeListItem>
    );
  };

  const findAllSpecificValues = (): SpecificValue[] => {
    let allSpecificValues: SpecificValue[] = [];
    const compareValueOnly = (a: SpecificValue, b: SpecificValue) => {
      return a.value === b.value;
    };
    parameter.paramIds.forEach((linkedParam) => {
      if (clientContextVariables) {
        const dataSourceCCVs = clientContextVariables[linkedParam.datasetUid];
        if (dataSourceCCVs) {
          const ccvForParameter = dataSourceCCVs.find((ccv) => ccv.name === linkedParam.name);
          if (ccvForParameter) {
            allSpecificValues = unionWith(
              allSpecificValues,
              ccvForParameter?.suggestedValues?.valueList,
              compareValueOnly
            );
          }
        }
      }
    });
    // If a specific value does not have a displayName defined, then use the value for the displayName
    return allSpecificValues.map((specificValue) => {
      if (!specificValue.displayName) {
        // For date parameters, format the value so it doesn't appear in ISO format
        const displayName =
          parameter.dataType === SoQLType.SoQLFloatingTimestampT
            ? formatDate(specificValue.value, 'l')
            : specificValue.value;
        return { value: specificValue.value, displayName: displayName };
      } else return specificValue;
    });
  };

  const allValues = findAllSpecificValues();
  const dropdownValues = allValues.map((value) => dropdownValue(value));

  const footerProps: FilterFooterProps = {
    onClickReset: onResetParameter,
    showApplyButton: false
  };

  return (
    <div className="parameter-dropdown-options">
      <ForgeList dense selectedValue={parameter.overrideValue}>
        {dropdownValues}
      </ForgeList>
      <FilterFooter {...footerProps} />
    </div>
  );
};

export default ParameterDropdown;
