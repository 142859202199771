import React from 'react';
import { isEmpty, get } from 'lodash';
import { useProcessDiscoveryResults } from 'common/visualizations/views/agGridReact/customHooks/useProcessDiscoveryResults';
import { ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { ContextParams } from 'common/visualizations/views/agGridReact/helpers/processDiscoveryHelper';

interface ContentProps {
  primaryKeyValue: string | number;
  contextParams: ContextParams;
}

export interface ProcessDescriptor {
  id: string;
  name: string;
}

export interface ProcessDiscoveryResults {
  applicationDescriptors?: any[];
  processDescriptors?: ProcessDescriptor[];
  serviceRequestDescriptors?: any[];
}

const LinksList: React.FC<{ descriptors: any[] }> = ({ descriptors }) => {
  if (isEmpty(descriptors)) {
    return null;
  }

  return (
    <div>
      <ul>
        {descriptors.map((entry, index) => (
          <ForgeListItem key={index}>
            <a href={entry.url} target="_blank" rel="noopener noreferrer">
              {entry.name}
            </a>
          </ForgeListItem>
        ))}
      </ul>
    </div>
  );
};

const ProcessDiscoveryContent: React.FC<ContentProps> = ({ primaryKeyValue, contextParams }) => {
  const {
    loading,
    processDiscoveryResults
  }: { loading: boolean; processDiscoveryResults: ProcessDiscoveryResults } = useProcessDiscoveryResults(
    primaryKeyValue,
    contextParams
  );

  const { applicationDescriptors = [] } = get(processDiscoveryResults, '[0]', {});
  if (loading) {
    return <div className="spinner-default" data-testid="process-discovery-spinner"></div>;
  }

  if (isEmpty(processDiscoveryResults)) {
    return <div data-testid="process-discovery-no-result">No results found</div>;
  }

  return (
    <div className="links">
      <ForgeList>
        <LinksList descriptors={applicationDescriptors} />
      </ForgeList>
    </div>
  );
};

export default ProcessDiscoveryContent;
